exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".root---2k7yS{min-width:100vw;min-height:100vh;color:#fff}.loader-box---2oz8-,.root---2k7yS{display:flex;flex-direction:column;background-color:#000010}.loader-box---2oz8-{width:100vw;height:100vh;position:fixed;z-index:1000;justify-content:center;align-items:center;z-index:2000;opacity:1}.loader-box--hide---2TTQN{transition:opacity 1s 1s;opacity:0}.loader-box--hide-short---YpAlA{transition:opacity 1s .5s;opacity:0}", ""]);

// Exports
exports.locals = {
	"root": "root---2k7yS",
	"loader-box": "loader-box---2oz8-",
	"loader-box--hide": "loader-box--hide---2TTQN",
	"loader-box--hide-short": "loader-box--hide-short---YpAlA"
};