import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import css from './App.module.scss';

import { query } from '../scripts/general';
import { updateInitialInfo, updateWindowWidth } from '../redux/actions/initialActions';
import { requestSessions } from '../redux/actions/playerActions';

import Customize from './customize/Customize';
import Loader from './loader/Loader';
import Main from './main/Main';

function App({ updateInitialInfo, initialInfo, requestSessions, players, updateWindowWidth }) {
  const { fullExperience, role, industry, initialFinished } = initialInfo;

  const [activeView, setActiveView] = useState('loader');

  function handleResize() {
    if (window.innerWidth > 1160) {
      updateWindowWidth(1161);
    } else if (window.innerWidth <= 1160 && window.innerWidth > 1100) {
      updateWindowWidth(1160);
    } else if (window.innerWidth <= 1100 && window.innerWidth > 1050) {
      updateWindowWidth(1100);
    } else if (window.innerWidth <= 1050 && window.innerWidth > 1000) {
      updateWindowWidth(1050);
    } else if (window.innerWidth <= 1000 && window.innerWidth > 830) {
      updateWindowWidth(1000);
    } else if (window.innerWidth <= 830 && window.innerWidth > 650) {
      updateWindowWidth(830);
    } else if (window.innerWidth <= 650 && window.innerWidth > 500) {
      updateWindowWidth(650);
    } else if (window.innerWidth <= 500) {
      updateWindowWidth(500);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    updateInitialInfo(query);
    handleResize();
  }, []);

  useEffect(() => {
    if (!fullExperience) {
      requestSessions(initialInfo);
    } else if (role && industry) {
      requestSessions(initialInfo);
    }
  }, [initialFinished, role, industry, fullExperience]);

  useEffect(() => {
    if (!initialFinished) {
      setActiveView('loader');
    } else if (!fullExperience && !players) {
      setActiveView('loader');
    } else if (
      !fullExperience &&
      players &&
      activeView !== 'loader-players' &&
      activeView !== 'players'
    ) {
      setActiveView('loader-players');
      setTimeout(() => setActiveView('players'), 2000);
    } else if (fullExperience && !players && activeView !== 'customize') {
      setActiveView('loader-customize');
      setTimeout(() => setActiveView('customize'), 1500);
    } else if (
      fullExperience &&
      players &&
      activeView !== 'customize-players' &&
      activeView !== 'players'
    ) {
      setActiveView('customize-players');
      setTimeout(() => setActiveView('players'), 2000);
    }
  }, [initialFinished, fullExperience, players, activeView]);

  return (
    <div className={css.root}>
      {activeView.includes('loader') ? (
        <div
          className={`${css['loader-box']} ${
            activeView.includes('players')
              ? css['loader-box--hide']
              : activeView.includes('customize')
              ? css['loader-box--hide-short']
              : ''
          }`}
        >
          <Loader />
        </div>
      ) : (
        ''
      )}
      {activeView.includes('customize') ? <Customize hide={activeView.includes('players')} /> : ''}
      {activeView.includes('players') ? <Main /> : ''}
    </div>
  );
}

App.propTypes = {
  updateInitialInfo: PropTypes.func.isRequired,
  initialInfo: PropTypes.object.isRequired,
  requestSessions: PropTypes.func.isRequired,
  players: PropTypes.array,
  updateWindowWidth: PropTypes.func.isRequired,
};

function mapsStateToProps({ initial, players }) {
  return {
    initialInfo: initial,
    players: players.players,
  };
}

const mapDispatchToProps = {
  updateInitialInfo,
  requestSessions,
  updateWindowWidth,
};

export default connect(mapsStateToProps, mapDispatchToProps)(App);
