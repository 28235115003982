import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import css from './StoriesContainer.module.scss';

import Story from './story/Story';

function StoriesContainer({ players, activePlayer, handleClick, windowWidth }) {
  const [showStories, setShowStories] = useState(null);

  useEffect(() => {
    if (windowWidth > 831) {
      setShowStories(null);
    }
  }, [windowWidth]);

  function handleClickMiddleware(number) {
    setShowStories(false);
    handleClick(number);
  }

  return (
    <div className={`${css.root} u-page-width-stories`}>
      <div
        className={`${css.watchMore} ${showStories ? css['watchMore--open'] : ''}`}
        onClick={() => setShowStories(!showStories)}
      >
        View more stories
      </div>
      <div
        className={`${css.stories} ${
          showStories ? css['stories--show'] : showStories !== null ? css['stories--hide'] : ''
        }`}
      >
        {players.map((player, i) => {
          let playerNum = i + 1;
          let active = activePlayer === playerNum;
          return (
            <Story
              playerInfo={player}
              number={playerNum}
              key={'sskyplayer' + playerNum}
              active={active}
              handleClick={handleClickMiddleware}
              showArrow={players.length < 5}
              watchText={player.action}
            />
          );
        })}
      </div>
    </div>
  );
}

StoriesContainer.propTypes = {
  players: PropTypes.array.isRequired,
  activePlayer: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

export default StoriesContainer;
