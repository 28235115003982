import 'core-js/stable';
import '@babel/polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';

import configureStore from './redux/configureStore';
import './styles/index.module.scss';
import './styles/utilities.scss';
import { AppContainer } from 'react-hot-loader';
import App from './components/App';

import { sskySegments } from './scripts/general';
sskySegments;
window.sskySegments = sskySegments;

export const store = configureStore();

render(
  <AppContainer>
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  </AppContainer>,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./components/App.js', () => {
    const NextRootContainer = require('./components/App.js').default;
    render(<NextRootContainer />, document.getElementById('app'));
  });
}
