import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import css from './Main.module.scss';
import { updateActivePlayer } from '../../redux/actions/playerActions';

import PlayersContainer from './playersContainer/PlayersContainer';
import StoriesContainer from './storiesContainer/StoriesContainer';
import Search from './search/Search';
import Footer from './footer/Footer';

function Main({ players, mainPlayer, updateActivePlayer, SF, maxOrder, windowWidth }) {
  const [activePlayer, setActivePlayer] = useState(mainPlayer);

  function switchActivePlayer(newPlayer, timeStamp) {
    setActivePlayer(newPlayer);
    updateActivePlayer(activePlayer, newPlayer, maxOrder, timeStamp);
  }

  useEffect(() => {
    setActivePlayer(mainPlayer);
  }, [mainPlayer]);

  return (
    <div className={css.root}>
      <div className={`${css.header} u-page-width`}>
        <div className={css['header__logo']}>
          <a href='https://sundaysky.com/' target='_blank' rel='noopener noreferrer'>
            <img
              className={css['header__logo__image']}
              src='assets/logo-sundaysky-singleline.svg'
              alt='SundaySky Logo'
            />
          </a>
        </div>
        <h1 className={css.header__title}>Revolutionize Your CX</h1>
        <Search />
      </div>
      <PlayersContainer players={players} activePlayer={activePlayer} SF={SF} />
      <StoriesContainer
        players={players}
        activePlayer={activePlayer}
        handleClick={switchActivePlayer}
        windowWidth={windowWidth}
      />
      <Footer />
    </div>
  );
}

Main.propTypes = {
  players: PropTypes.array.isRequired,
  mainPlayer: PropTypes.number.isRequired,
  updateActivePlayer: PropTypes.func.isRequired,
  SF: PropTypes.string.isRequired,
  maxOrder: PropTypes.number.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

function mapsStateToProps({ players, initial }) {
  return {
    players: players.players,
    mainPlayer: players.activePlayer,
    SF: initial.SF,
    maxOrder: players.maxOrder,
    windowWidth: initial.windowWidth,
  };
}

const mapDispatchToProps = {
  updateActivePlayer,
};

export default connect(mapsStateToProps, mapDispatchToProps)(Main);
