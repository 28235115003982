import 'whatwg-fetch';
import * as log from './log';

import { storiesInfo } from '../redux/reducers/storiesInfo';

export { requestSession };

async function requestSession(
  SF,
  ENV,
  DEV_MODE,
  variation,
  id,
  number,
  storyOverride,
  role,
  industry,
  storyInfo
) {
  let endpoint = 'https://' + SF + '.web.sundaysky.com/create_video_session?';

  if (ENV !== 'prod') {
    endpoint = replaceToSb(endpoint);
  }

  if (role && industry) {
    endpoint += 'id=generic&role=' + escape(role) + '&industry=' + escape(industry);
  } else {
    endpoint += 'id=' + id;
  }
  if (storyOverride) {
    endpoint += '&storyoverride=' + storyOverride;
  } else {
    endpoint += '&variation=' + variation;
  }
  endpoint += '&cta=true';
  log.info('EP ' + number + ': ' + endpoint);

  if (!DEV_MODE && id !== 'staticVersion') {
    const options = {
      method: 'GET',
      headers: {},
    };
    log.info('Fetching video for Player ' + number + ', Story: ' + variation);
    const response = await fetch(endpoint, options).catch(err => {
      log.error(err);
    });

    if ((await response.status) !== 501) {
      log.step('Session for Player ' + number + ' Received');
      return getMetadata(await response.text(), storyInfo);
    } else {
      log.error(
        'Session for Player ' +
          number +
          ' failed, returning generic version for story: ' +
          variation
      );
      if (variation === 'SO') {
        console.log(variation);
        variation = storiesInfo['1'].nameForHfe;
        storyInfo = storiesInfo['1'];
      }
      log.step(
        'Static Video For Player ' +
          number +
          ' https://player.sundaysky.com/customers/masterpiece/fallbacks/' +
          variation +
          '/session64.txt'
      );

      return { session: storyInfo.fallbackSession, storyInfo, fallback: true };
    }
  } else {
    if (id === 'staticVersion') {
      log.step('No ID. Player ' + number + ', returning generic version for story: ' + variation);
    } else {
      log.step(
        'Player ' +
          number +
          ', variation marked as DevMode. Returning generic version for story: ' +
          variation
      );
      log.step(
        'Static Video For Player ' +
          number +
          ' https://player.sundaysky.com/customers/masterpiece/fallbacks/' +
          variation +
          '/session64.txt'
      );
    }

    return { session: storyInfo.fallbackSession, storyInfo, fallback: true };
  }
}

function replaceToSb(ep) {
  return ep.replace('.sundaysky.', '.sundaysky-sandbox.');
}

async function getMetadata(session, storyInfo) {
  let metadata;

  try {
    metadata = JSON.parse(atob(session))._links.metadata.href;
  } catch {
    metadata = null;
  }
  if (metadata) {
    const response = await fetch(metadata, { method: 'GET' }).catch(err => {
      log.error(err);
    });

    if ((await response.status) !== 501) {
      let scenes = JSON.parse(await response.text()).scenes;
      if (storyInfo.nameForHfe !== 'SO') {
        scenes.forEach(scene => {
          let initialScene = storyInfo.scenes.find(
            initialSceneInfo => scene.name === initialSceneInfo.name
          );
          if (initialScene) {
            initialScene.timeStamp = scene.start;
            initialScene.timeStampEnd = scene.end;
          }
        });

        storyInfo.scenes.forEach(scene => {
          let jsonScene = scenes.find(sceneInfo => scene.name === sceneInfo.name);
          if (!jsonScene) {
            scene.display = false;
          }
        });
      } else {
        let newScenes = [];
        scenes.forEach(scene => {
          let initialScene = storyInfo.scenes.find(
            initialSceneInfo => scene.name === initialSceneInfo.name
          );
          if (initialScene) {
            initialScene.timeStamp = scene.start;
            initialScene.timeStampEnd = scene.end;
            newScenes.push(initialScene);
          }
        });
        storyInfo.scenes = newScenes;
      }
    }
    return { session, storyInfo, fallback: false };
  } else {
    return { session, storyInfo, fallback: false };
  }
}
