import React from 'react';
import PropTypes from 'prop-types';

import { isIE } from '../../scripts/general';
import css from './Loader.module.scss';

function Loader({ text, style }) {
  return (
    <div className={css.root} style={style}>
      {isIE ? (
        <div className={css.gif}>
          <img className={css.gif__img} src='assets/preloader-gif.gif' alt='Loader' />
        </div>
      ) : (
        <div>
          <svg id='loading' className={css.svg} viewBox='0 0 100 80'>
            <g transform='translate(50 0) scale(0.707 0.707) rotate(45 0 0)'>
              <rect
                className={`${css.rect} ${css.rect1}`}
                x='0'
                y='0'
                width='24'
                height='24'
                rx='2'
                ry='2'
                fill='#ee672c'
              />
              <rect
                className={`${css.rect} ${css.rect2}`}
                x='0'
                y='0'
                width='24'
                height='24'
                rx='2'
                ry='2'
                fill='#464646'
              />
              <rect
                className={`${css.rect} ${css.rect3}`}
                x='0'
                y='0'
                width='24'
                height='24'
                rx='2'
                ry='2'
                fill='#808080'
              />
              <rect
                className={`${css.rect} ${css.rect4}`}
                x='0'
                y='0'
                width='24'
                height='24'
                rx='2'
                ry='2'
                fill='#464646'
              />
              <rect
                className={`${css.rect} ${css.rect5}`}
                x='0'
                y='0'
                width='24'
                height='24'
                rx='2'
                ry='2'
                fill='#808080'
              />
              <rect
                className={`${css.rect} ${css.rect6}`}
                x='0'
                y='0'
                width='24'
                height='24'
                rx='2'
                ry='2'
                fill='#464646'
              />
              <rect
                className={`${css.rect} ${css.rect7}`}
                x='0'
                y='0'
                width='24'
                height='24'
                rx='2'
                ry='2'
                fill='#808080'
              />
            </g>
          </svg>
        </div>
      )}
      <div className={css.logo}>
        {text ? (
          <div className={css.logo__text}>{text}</div>
        ) : (
          <img
            src='assets/logo-sundaysky-singleline.svg'
            alt='SundaySky Logo'
            className={css.logo__img}
          />
        )}
      </div>
    </div>
  );
}

Loader.propTypes = {
  text: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  style: PropTypes.object,
};

export default Loader;
