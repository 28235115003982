import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import css from './SearchResults.module.scss';
import '../../playersContainer/player/player.scss';
// import { isMobile as MobileDevice } from '../../../../scripts/general';

function SearchResults({ results, searchTerm, onExit }) {
  const [show, setShow] = useState(true);
  const [scene, setScene] = useState(results[0].fileName);
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    if (!player) {
      setPlayer(document.getElementById('sskyplayer6'));
    } else {
      player.classList.add(css.player);
      if (navigator.appVersion.indexOf('Win') !== -1) player.classList.add('play-windows');
      // if (!MobileDevice) {
      //   setTimeout(() => {
      //     player.play();
      //   }, 1000);
      // }
    }
  }, [player]);

  useEffect(() => {
    if (player) {
      player.session =
        'https://player.sundaysky.com/customers/masterpiece/individualScenes/' + scene + '.mp4';
    }
  }, [scene]);

  function hide() {
    player.pause();
    setShow(false);
    setTimeout(() => {
      onExit();
    }, 500);
  }
  return (
    <div className={`${css.root} ${show ? '' : css['root--hide']}`}>
      <div className={css.overlay} onClick={() => hide()}></div>
      <div className={`${css.playerWrapper} u-page-width`}>
        <div className={css.playerSide}>
          <div className={`${css.playerSide__title}`}>
            Search results for
            <div className={css.searchTerm}>{"'" + searchTerm + "'"}</div>
          </div>
          <div className={css.SearchResults}>
            {results.map((result, i) => {
              return (
                <div
                  key={'result-' + i}
                  className={`${css.result} ${
                    scene === result.fileName ? css['result--current'] : ''
                  }`}
                  onClick={() => setScene(result.fileName)}
                >
                  {result.sceneName}
                  {/* 
                  {scene === result.fileName ? (
                    <div className={css.nowPlaying}>Now Playing</div>
                  ) : (
                    ''
                  )} */}
                </div>
              );
            })}
          </div>
          <div className={css.back}>
            <div className={css.back__link} onClick={() => hide()}>
              Back to VX
            </div>
          </div>
        </div>
        <div className={css.playerContainer}>
          <sundaysky-video
            session={
              'https://player.sundaysky.com/customers/masterpiece/individualScenes/' +
              scene +
              '.mp4'
            }
            id={'sskyplayer6'}
            no-captions={true}
            transcript-button='hide'
            analytics-token='SF-00000703'
            playsinline='true'
            poster='assets/invitation.gif'
            pre-roll='assets/preloader.gif'
          />
        </div>
      </div>
    </div>
  );
}

SearchResults.propTypes = {
  results: PropTypes.array.isRequired,
  searchTerm: PropTypes.string.isRequired,
  onExit: PropTypes.func.isRequired,
};

export default SearchResults;
