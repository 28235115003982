export const SESSIONS_RECEIVED_SUCCESS = 'SESSION_RECEIVED_SUCCESS';
export const UPDATE_INITIAL_SUCCESS = 'UPDATE_INITIAL_SUCCESS';
export const UPDATE_CUSTOMIZE_SUCCESS = 'UPDATE_CUSTOMIZE_SUCCESS';
export const UPDATE_ACTIVE_PLAYER_SUCCESS = 'UPDATE_ACTIVE_PLAYER_SUCCESS';
export const UPDATE_PLAYER_IS_PLAYING = 'UPDATE_PLAYER_IS_PLAYING';
export const UPDATE_PLAYER_PROGRESS = 'UPDATE_PLAYER_PROGRESS';
export const UPDATE_ACTIVE_SCENE = 'UPDATE_ACTIVE_SCENE';
export const UPDATE_SEARCH_ACTIVE = 'UPDATE_SEARCH_ACTIVE';
export const UPDATE_WINDOW_WIDTH = 'UPDATE_WINDOW_WIDTH';
export const UPDATE_PLAYER_ACTION = 'UPDATE_PLAYER_ACTION';
