import * as types from './actionTypes';
import { ENV, isMobile } from '../../scripts/general';
import { storiesInfo } from '../reducers/storiesInfo';

import { requestSession } from '../../scripts/requestSession';

export function sessionReceivedSuccess(players) {
  return { type: types.SESSIONS_RECEIVED_SUCCESS, players };
}

export function updateActivePlayerSuccess(newPlayerNumber, oldPlayerNumber, maxOrder) {
  return { type: types.UPDATE_ACTIVE_PLAYER_SUCCESS, newPlayerNumber, oldPlayerNumber, maxOrder };
}

export function updatePlayerActionSuccess(playerInfo) {
  return {
    type: types.UPDATE_PLAYER_ACTION,
    playerInfo,
  };
}

export function updatePlayerAction(playerInfo, action) {
  return function (dispatch) {
    let newPlayerInfo = { ...playerInfo, action };
    dispatch(updatePlayerActionSuccess(newPlayerInfo));
  };
}

let startPlay;

export function updateActivePlayer(activePlayerNumber, newPlayerNumber, maxOrder, timeStamp) {
  return function (dispatch) {
    clearTimeout(startPlay);

    let player = document.getElementById('sskyplayer' + activePlayerNumber);
    player.pause();

    let newPlayer = document.getElementById('sskyplayer' + newPlayerNumber);

    if (!isMobile || newPlayer.currentTime !== 0) {
      if (activePlayerNumber !== newPlayerNumber) {
        startPlay = setTimeout(() => {
          if (timeStamp) newPlayer.currentTime = timeStamp;
          newPlayer.play();
        }, 1000);
      } else {
        startPlay = setTimeout(() => {
          if (timeStamp) newPlayer.currentTime = timeStamp;
          newPlayer.play();
        }, 200);
      }
    }

    return dispatch(updateActivePlayerSuccess(newPlayerNumber, activePlayerNumber, maxOrder));
  };
}

export function requestSessions({ SF, id, variations, role, industry, storyOverride }) {
  return function (dispatch) {
    let requests = [];
    variations.forEach((variation, i) => {
      let { DEV_MODE, nameForHfe } = storiesInfo[variation];
      requests.push(
        requestSession(
          SF,
          ENV,
          DEV_MODE,
          nameForHfe,
          id,
          i + 1,
          storyOverride,
          role,
          industry,
          storiesInfo[variation]
        )
      );
    });

    return Promise.all(requests).then(([...sessions]) => {
      let players = sessions.length > 0 ? [] : null;
      sessions.forEach(({ session, storyInfo, fallback }, i) => {
        let player = {
          ...storyInfo,
          playerElement: {},
          session,
          fallback,
          order: i + 1,
          num: i + 1,
        };
        players.push(player);
      });
      return dispatch(sessionReceivedSuccess(players));
    });
  };
}
