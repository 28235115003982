import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import css from './Customize.module.scss';

import Question from './question/Question';
import Loader from '../loader/Loader';

import { updateCustomizeAction } from '../../redux/actions/initialActions';

function Customize({
  role,
  industry,
  updateCustomizeAction,
  roles,
  industries,
  hide,
  windowWidth,
}) {
  const [currentQuestions, setCurrentQuestion] = useState('role');

  useEffect(() => {
    if (role) {
      if (!industry) {
        setCurrentQuestion('industry');
      } else {
        setTimeout(() => {
          setCurrentQuestion('none');
        }, 1000);
      }
    }
  }, [role, industry]);

  function handleChange(question, value) {
    updateCustomizeAction({ [question]: value });
  }

  return (
    <div className={`${css.root} ${hide ? css['root--hide'] : ''}`}>
      <header className={css.header}>
        <div className={css['header--main']}>Revolutionize your CX</div>
        <div className={css['header--sub']}>
          Video-powered experiences (VX) <br className={css.mobile2} /> empower brands{' '}
          <br className={css.mobile} />
          to engage, educate, <br className={css.mobile2} />
          and inspire customers.
        </div>
        <div className={`${css['header--sub']} ${css['header--sub--lower']}`}>
          This VX will show you everything <br className={css.mobile3} />
          you need to know about VX.
        </div>
        <div className={css['header__logo']}>
          <img
            className={css['header__logo__image']}
            src='../../../assets/logo-sundaysky-singleline.svg'
            alt='SundaySky Logo'
          ></img>
        </div>
      </header>
      <div className={`${css.bottom} ${role && industry ? css['bottom--hide'] : ''}`}>
        {currentQuestions === 'role' ? (
          <Question
            answers={roles}
            selected={role}
            question='role'
            handleChange={handleChange}
            text='First, choose your role'
            windowWidth={windowWidth}
          />
        ) : null}
        {currentQuestions === 'industry' ? (
          <Question
            answers={industries}
            selected={industry}
            question='industry'
            handleChange={handleChange}
            text='Next, Choose your industry'
            windowWidth={windowWidth}
          />
        ) : null}
        {currentQuestions === 'none' ? (
          <Loader text='Preparing your experience' style={{ marginTop: '10rem' }} />
        ) : null}
      </div>
    </div>
  );
}

Customize.propTypes = {
  role: PropTypes.string,
  industry: PropTypes.string,
  updateCustomizeAction: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  industries: PropTypes.array.isRequired,
  hide: PropTypes.bool.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

function mapsStateToProps({ initial }, ownProps) {
  return {
    role: initial.role,
    industry: initial.industry,
    roles: initial.roles,
    industries: initial.industries,
    hide: ownProps.hide,
    windowWidth: initial.windowWidth,
  };
}

const mapDispatchToProps = {
  updateCustomizeAction,
};

export default connect(mapsStateToProps, mapDispatchToProps)(Customize);
