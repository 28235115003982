import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function initialReducer(state = initialState.initial, action) {
  switch (action.type) {
    case types.UPDATE_INITIAL_SUCCESS:
      return { ...state, ...action.info };
    case types.UPDATE_CUSTOMIZE_SUCCESS:
      return { ...state, ...action.info };
    case types.UPDATE_SEARCH_ACTIVE:
      return { ...state, searchActive: action.searchActive };
    case types.UPDATE_WINDOW_WIDTH:
      return { ...state, windowWidth: action.windowWidth };
    default:
      return state;
  }
}
