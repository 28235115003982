const escape = require('escape-html');
import * as types from './actionTypes';

export function updateInitialInfoAction(info) {
  return { type: types.UPDATE_INITIAL_SUCCESS, info };
}

export function updateCustomizeAction(info) {
  return { type: types.UPDATE_CUSTOMIZE_SUCCESS, info };
}

export function updateSearchActive(searchActive) {
  return { type: types.UPDATE_SEARCH_ACTIVE, searchActive };
}

export function updateWindowWidth(windowWidth) {
  return { type: types.UPDATE_WINDOW_WIDTH, windowWidth };
}

export function updateInitialInfo(query) {
  return function (dispatch) {
    let variations,
      id,
      storyOverride,
        client,
      fullExperience = false;

    variations = query.get('variations') ? escape(query.get('variations')) : '';
    if (!variations || variations.length === 0 || variations[0] === '') {
      variations = ['1', '7', '2', '3', '4', '5'];
    } else {
      variations = variations.split(escape(','));
    }

    id = query.get('id') ? escape(query.get('id')) : '';
    if (!id || id.length === 0 || id === '') {
      fullExperience = true;
      id = 'generic';
    }

    storyOverride = query.get('so') ? escape(query.get('so')) : null;

    if (storyOverride) {
      variations = ['0'];
    }

    client = query.get('client') ? escape(query.get('client')) : '';
    if (client && id.length !== 0 && client !== '') {
      if (client.toLowerCase() === 'acquia') {
        fullExperience = false;
        id = 'generic';
        variations = ['6', '2', '3', '4', '5'];
      }
    }



    return dispatch(
      updateInitialInfoAction({
        variations,
        id,
        storyOverride,
        fullExperience,
        initialFinished: true,
      })
    );
  };
}
