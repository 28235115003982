export { ENV, isMobile, sskySegments, query, isIE };

let isMobile;
try {
  isMobile = /Android|webOS|iPhone|iPad|iPod|pocket|psp|kindle|avantgo|blazer|midori|Tablet|Palm|maemo|plucker|phone|BlackBerry|symbian|IEMobile|mobile|ZuneWP7|Windows Phone|Opera Mini/i.test(
    navigator.userAgent
  );
} catch (e) {
  console.log('Error in isMobile');
  isMobile = false;
}

let ENV;

if (window.location.href.indexOf('localhost') !== -1) {
  ENV = 'localhost';
} else if (
  window.location.href.indexOf('dev') !== -1 ||
  window.location.href.indexOf('raz') !== -1
) {
  ENV = 'dev';
} else if (window.location.href.indexOf('uat') !== -1) {
  ENV = 'uat';
} else {
  ENV = 'prod';
}

let isIE = /*@cc_on!@*/ false || !!document.documentMode;

//for reporting
let query = new URLSearchParams(window.location.search);
let sskySegments = {};
sskySegments['env'] = ENV;
for (let pair of query.entries()) {
  sskySegments[escape(pair[0])] = escape(pair[1]);
}
