import React from 'react';

import css from './Footer.module.scss';

function Footer() {
  return (
    <div className={`${css.root} u-page-width`}>
      <div className={css.copyright}>
        Copyright &copy;&nbsp;
        <a href='https://sundaysky.com/' target='_blank' rel='noopener noreferrer'>
          SundaySky
        </a>
      </div>
      <div className={css.social}>
        <div className={css.social__text}>Follow and Connect:</div>
        <div className={css.social__icons}>
          <a
            href='https://sundaysky.com/'
            target='_blank'
            rel='noopener noreferrer'
            className={`${css['social__icons--sskyLogo']}`}
          >
            <img src='assets/SSKY.svg' alt='SundaySky Logo' />
          </a>
          <a
            href='https://twitter.com/sundaysky?s=20'
            target='_blank'
            rel='noopener noreferrer'
            className={`${css['social__icons--image']} ${css['social__icons--image--twitter']}`}
          ></a>
          <a
            href='https://www.instagram.com/sundaysky_inc'
            target='_blank'
            rel='noopener noreferrer'
            className={`${css['social__icons--image']} ${css['social__icons--image--instagram']}`}
          ></a>
          <a
            href='https://www.linkedin.com/company/sundaysky'
            target='_blank'
            rel='noopener noreferrer'
            className={`${css['social__icons--image']} ${css['social__icons--image--linkedin']}`}
          ></a>
          <a
            href='https://www.facebook.com/SundaySkyVideo/'
            target='_blank'
            rel='noopener noreferrer'
            className={`${css['social__icons--image']} ${css['social__icons--image--facebook']}`}
          ></a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
