export default {
  initial: {
    id: null,
    variations: [],
    role: null,
    industry: null,
    fullExperience: false,
    SF: 'SF-00000703',
    windowWidth: 0,
    roles: [
      { text: 'Business Leader', value: 'Digital Business Leaders' },
      { text: 'Marketing Leader', value: 'Marketing Leaders' },
      { text: 'Advertiser', value: 'Advertisers' },
      { text: 'Other', value: 'Other' },
      { text: 'CX Leader', value: 'CX Leaders' },
      { text: 'Agency Partner', value: 'Agency Partners' },
      { text: 'Creative Leader', value: 'Creatives' },
      { text: 'Tech Innovator', value: 'Tech Innovators' },
      { text: 'CMO', value: 'CMOs' },
      { text: 'Customer Care Expert', value: 'Customer Care Experts' },
      { text: 'Media Buyer', value: 'Media Buyers' },
      { text: 'Legal Expert', value: 'Legal Experts' },
    ],
    industries: [
      { text: 'Telco', value: 'Telco' },
      { text: 'Agencies', value: 'Agencies' },
      { text: 'Finance', value: 'Finance' },
      { text: 'DTC Brands', value: 'DTC' },
      { text: 'Insurance', value: 'Insurance' },
      { text: 'Healthcare', value: 'Healthcare' },
      { text: 'Travel', value: 'Travel' },
      { text: 'Other', value: 'Other' },
      { text: 'Retail', value: 'Retail' },
      { text: 'Technology', value: 'Technology' },
      { text: 'E-Commerce', value: 'E-Commerce' },
    ],
    searchActive: false,
    searchTerms: [
      //*** VX Modularity -- HowVXWorks
      // Indroduction -- Opening
      { text: 'opening', sceneName: 'Indroduction', fileName: 'Opening' },
      { text: 'intro', sceneName: 'Indroduction', fileName: 'Opening' },
      { text: 'consumer', sceneName: 'Indroduction', fileName: 'Opening' },
      { text: 'engagement', sceneName: 'Indroduction', fileName: 'Opening' },
      { text: 'VX', sceneName: 'Indroduction', fileName: 'Opening' },
      { text: 'platform', sceneName: 'Indroduction', fileName: 'Opening' },
      { text: 'experience', sceneName: 'Indroduction', fileName: 'Opening' },
      { text: 'indroduction', sceneName: 'Indroduction', fileName: 'Opening' },
      // VX Capabilities -- TenetsOfVX
      { text: 'capabilities', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      { text: 'basics', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      { text: 'scale', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      { text: 'linear', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      { text: 'standard', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      { text: 'static', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      { text: 'VX', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      { text: 'video powered experience', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      { text: 'video', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      { text: 'powered', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      { text: 'experience', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      { text: 'tenants', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      { text: 'dynamic', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      { text: 'modularity', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      { text: 'outcomes', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      { text: 'overview', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      { text: 'VX', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      { text: 'data', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      { text: 'real time', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      { text: 'real', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      { text: 'time', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      { text: 'render', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      { text: 'action', sceneName: 'VX Capabilities', fileName: 'TenetsOfVX' },
      // Scene Modularity -- SceneModularity
      { text: 'scenes', sceneName: 'Scene Modularity', fileName: 'SceneModularity' },
      { text: 'modular', sceneName: 'Scene Modularity', fileName: 'SceneModularity' },
      { text: 'library', sceneName: 'Scene Modularity', fileName: 'SceneModularity' },
      { text: 'variations', sceneName: 'Scene Modularity', fileName: 'SceneModularity' },
      { text: 'modularity', sceneName: 'Scene Modularity', fileName: 'SceneModularity' },
      { text: 'content', sceneName: 'Scene Modularity', fileName: 'SceneModularity' },
      { text: 'footage', sceneName: 'Scene Modularity', fileName: 'SceneModularity' },
      { text: 'graphics', sceneName: 'Scene Modularity', fileName: 'SceneModularity' },
      { text: 'design', sceneName: 'Scene Modularity', fileName: 'SceneModularity' },
      { text: 'scene', sceneName: 'Scene Modularity', fileName: 'SceneModularity' },
      { text: 'sequencing', sceneName: 'Scene Modularity', fileName: 'SceneModularity' },
      { text: 'story', sceneName: 'Scene Modularity', fileName: 'SceneModularity' },
      { text: 'video', sceneName: 'Scene Modularity', fileName: 'SceneModularity' },
      { text: 'topic', sceneName: 'Scene Modularity', fileName: 'SceneModularity' },
      // Scene Flexibility -- SceneDynamics
      { text: 'scene', sceneName: 'Scene Flexibility', fileName: 'SceneDynamics' },
      { text: 'place holder', sceneName: 'Scene Flexibility', fileName: 'SceneDynamics' },
      { text: 'placeholder', sceneName: 'Scene Flexibility', fileName: 'SceneDynamics' },
      { text: 'holder', sceneName: 'Scene Flexibility', fileName: 'SceneDynamics' },
      { text: 'place', sceneName: 'Scene Flexibility', fileName: 'SceneDynamics' },
      { text: 'flexibility', sceneName: 'Scene Flexibility', fileName: 'SceneDynamics' },
      { text: 'content velocity', sceneName: 'Scene Flexibility', fileName: 'SceneDynamics' },
      { text: 'content', sceneName: 'Scene Flexibility', fileName: 'SceneDynamics' },
      { text: 'velocity', sceneName: 'Scene Flexibility', fileName: 'SceneDynamics' },
      { text: 'logic', sceneName: 'Scene Flexibility', fileName: 'SceneDynamics' },
      { text: 'data', sceneName: 'Scene Flexibility', fileName: 'SceneDynamics' },
      { text: 'dynamic', sceneName: 'Scene Flexibility', fileName: 'SceneDynamics' },
      { text: 'variation', sceneName: 'Scene Flexibility', fileName: 'SceneDynamics' },
      { text: 'variations', sceneName: 'Scene Flexibility', fileName: 'SceneDynamics' },
      // Scene Library -- SceneLibrary
      { text: 'scenes', sceneName: 'Scene Library', fileName: 'SceneLibrary' },
      { text: 'library', sceneName: 'Scene Library', fileName: 'SceneLibrary' },
      { text: 'scene library', sceneName: 'Scene Library', fileName: 'SceneLibrary' },
      { text: 'framework', sceneName: 'Scene Library', fileName: 'SceneLibrary' },
      { text: 'modularity', sceneName: 'Scene Library', fileName: 'SceneLibrary' },
      { text: 'modular', sceneName: 'Scene Library', fileName: 'SceneLibrary' },
      { text: 'story', sceneName: 'Scene Library', fileName: 'SceneLibrary' },
      { text: 'sequence', sceneName: 'Scene Library', fileName: 'SceneLibrary' },
      { text: 'video', sceneName: 'Scene Library', fileName: 'SceneLibrary' },
      // Delivery Channels -- DeliveryChannels
      { text: 'email', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'portal', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'on-site', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'site', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'programmatic', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'channel', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'delivery', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'reach', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'paid media', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'owned media', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'owned', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'paid', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'media', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'website', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'touchpoint', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'mobile app', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'mobile', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'app', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'landing page', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'page', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'landing', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'youtube', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'facebook', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'instagram', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'desktop', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'omni channel', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'omni', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'channel', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'portal', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'delivery channel', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'channels', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'delivery channels', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      { text: 'player', sceneName: 'Delivery Channels', fileName: 'DeliveryChannels' },
      // // CTA -- No chapter
      // { text: 'next steps', sceneName: 'CTA', fileName: 'VX Modularity' },
      //*** Why VX matters -- WhyVXMatters
      // The CX Challenge -- TheCXChallenge
      { text: 'CX', sceneName: 'The CX Challenge', fileName: 'TheCXChallenge' },
      { text: 'customer', sceneName: 'The CX Challenge', fileName: 'TheCXChallenge' },
      { text: 'experience', sceneName: 'The CX Challenge', fileName: 'TheCXChallenge' },
      { text: 'challenge', sceneName: 'The CX Challenge', fileName: 'TheCXChallenge' },
      { text: 'engage', sceneName: 'The CX Challenge', fileName: 'TheCXChallenge' },
      { text: 'digital', sceneName: 'The CX Challenge', fileName: 'TheCXChallenge' },
      { text: 'action', sceneName: 'The CX Challenge', fileName: 'TheCXChallenge' },
      { text: 'brands', sceneName: 'The CX Challenge', fileName: 'TheCXChallenge' },
      { text: 'connect', sceneName: 'The CX Challenge', fileName: 'TheCXChallenge' },
      { text: 'retention', sceneName: 'The CX Challenge', fileName: 'TheCXChallenge' },
      { text: 'aquisition', sceneName: 'The CX Challenge', fileName: 'TheCXChallenge' },
      { text: 'loyalty', sceneName: 'The CX Challenge', fileName: 'TheCXChallenge' },
      { text: 'connections', sceneName: 'The CX Challenge', fileName: 'TheCXChallenge' },
      { text: 'engagement', sceneName: 'The CX Challenge', fileName: 'TheCXChallenge' },
      { text: 'VX', sceneName: 'The CX Challenge', fileName: 'TheCXChallenge' },
      { text: 'behavior', sceneName: 'The CX Challenge', fileName: 'TheCXChallenge' },
      { text: 'use case', sceneName: 'The CX Challenge', fileName: 'TheCXChallenge' },
      { text: 'use', sceneName: 'The CX Challenge', fileName: 'TheCXChallenge' },
      { text: 'case', sceneName: 'The CX Challenge', fileName: 'TheCXChallenge' },
      { text: 'goals', sceneName: 'The CX Challenge', fileName: 'TheCXChallenge' },
      { text: 'KPI', sceneName: 'The CX Challenge', fileName: 'TheCXChallenge' },
      { text: 'action', sceneName: 'The CX Challenge', fileName: 'TheCXChallenge' },
      // Why SundaySky -- SSkyValueProp
      { text: 'SundaySky', sceneName: 'Why SundaySky', fileName: 'SSKYValueProp' },
      { text: 'value', sceneName: 'Why SundaySky', fileName: 'SSKYValueProp' },
      { text: 'engage', sceneName: 'Why SundaySky', fileName: 'SSKYValueProp' },
      { text: 'educate', sceneName: 'Why SundaySky', fileName: 'SSKYValueProp' },
      { text: 'inspire', sceneName: 'Why SundaySky', fileName: 'SSKYValueProp' },
      { text: 'why', sceneName: 'Why SundaySky', fileName: 'SSKYValueProp' },
      { text: 'use case', sceneName: 'Why SundaySky', fileName: 'SSKYValueProp' },
      { text: 'use', sceneName: 'Why SundaySky', fileName: 'SSKYValueProp' },
      { text: 'case', sceneName: 'Why SundaySky', fileName: 'SSKYValueProp' },
      { text: 'goals', sceneName: 'Why SundaySky', fileName: 'SSKYValueProp' },
      { text: 'KPI', sceneName: 'Why SundaySky', fileName: 'SSKYValueProp' },
      { text: 'action', sceneName: 'Why SundaySky', fileName: 'SSKYValueProp' },
      { text: 'measure', sceneName: 'Why SundaySky', fileName: 'SSKYValueProp' },
      // Moments in the Customer Journey -- FourMoments
      { text: 'journey', sceneName: 'Moments in the Customer Journey', fileName: 'FourMoments' },
      {
        text: 'lifecycle',
        sceneName: 'Moments in the Customer Journey',
        fileName: 'FourMoments',
      },
      { text: 'moments', sceneName: 'Moments in the Customer Journey', fileName: 'FourMoments' },
      { text: 'example', sceneName: 'Moments in the Customer Journey', fileName: 'FourMoments' },
      { text: 'case study', sceneName: 'Moments in the Customer Journey', fileName: 'FourMoments' },
      { text: 'case', sceneName: 'Moments in the Customer Journey', fileName: 'FourMoments' },
      { text: 'study', sceneName: 'Moments in the Customer Journey', fileName: 'FourMoments' },
      { text: 'comparison', sceneName: 'Moments in the Customer Journey', fileName: 'FourMoments' },
      { text: 'video', sceneName: 'Moments in the Customer Journey', fileName: 'FourMoments' },
      { text: 'use case', sceneName: 'Moments in the Customer Journey', fileName: 'FourMoments' },
      { text: 'use', sceneName: 'Moments in the Customer Journey', fileName: 'FourMoments' },
      { text: 'case', sceneName: 'Moments in the Customer Journey', fileName: 'FourMoments' },
      { text: 'value', sceneName: 'Moments in the Customer Journey', fileName: 'FourMoments' },
      // Outcomes & ROI -- TheValueOfVX
      { text: 'results', sceneName: 'Outcomes & ROI', fileName: 'TheValueOfVX' },
      { text: 'value', sceneName: 'Outcomes & ROI', fileName: 'TheValueOfVX' },
      { text: 'outcomes', sceneName: 'Outcomes & ROI', fileName: 'TheValueOfVX' },
      { text: 'breakthrough', sceneName: 'Outcomes & ROI', fileName: 'TheValueOfVX' },
      { text: 'ROI', sceneName: 'Outcomes & ROI', fileName: 'TheValueOfVX' },
      { text: 'example', sceneName: 'Outcomes & ROI', fileName: 'TheValueOfVX' },
      { text: 'case study', sceneName: 'Outcomes & ROI', fileName: 'TheValueOfVX' },
      { text: 'case', sceneName: 'Outcomes & ROI', fileName: 'TheValueOfVX' },
      { text: 'study', sceneName: 'Outcomes & ROI', fileName: 'TheValueOfVX' },
      { text: 'business', sceneName: 'Outcomes & ROI', fileName: 'TheValueOfVX' },
      { text: 'results', sceneName: 'Outcomes & ROI', fileName: 'TheValueOfVX' },
      // Case Study -- CaseStudy
      { text: 'outcomes', sceneName: 'Case Study', fileName: 'CaseStudy' },
      { text: 'customer', sceneName: 'Case Study', fileName: 'CaseStudy' },
      { text: 'story', sceneName: 'Case Study', fileName: 'CaseStudy' },
      { text: 'example', sceneName: 'Case Study', fileName: 'CaseStudy' },
      { text: 'ROI', sceneName: 'Case Study', fileName: 'CaseStudy' },
      { text: 'case study', sceneName: 'Case Study', fileName: 'CaseStudy' },
      { text: 'case', sceneName: 'Case Study', fileName: 'CaseStudy' },
      { text: 'study', sceneName: 'Case Study', fileName: 'CaseStudy' },
      { text: 'example', sceneName: 'Case Study', fileName: 'CaseStudy' },
      { text: 'use case', sceneName: 'Case Study', fileName: 'CaseStudy' },
      { text: 'use', sceneName: 'Case Study', fileName: 'CaseStudy' },
      //*** What defines VX -- WhatDefinesVX
      // Intro to Wide Range of VX -- WideRangeOfVXIntro
      { text: 'range', sceneName: 'Intro to Wide Range of VX', fileName: 'WideRangeOfVXIntro' },
      { text: 'solutions', sceneName: 'Intro to Wide Range of VX', fileName: 'WideRangeOfVXIntro' },
      { text: 'options', sceneName: 'Intro to Wide Range of VX', fileName: 'WideRangeOfVXIntro' },
      {
        text: 'smart video',
        sceneName: 'Intro to Wide Range of VX',
        fileName: 'WideRangeOfVXIntro',
      },
      { text: 'smart', sceneName: 'Intro to Wide Range of VX', fileName: 'WideRangeOfVXIntro' },
      { text: 'video', sceneName: 'Intro to Wide Range of VX', fileName: 'WideRangeOfVXIntro' },
      {
        text: 'personalized',
        sceneName: 'Intro to Wide Range of VX',
        fileName: 'WideRangeOfVXIntro',
      },
      {
        text: 'video powered experience',
        sceneName: 'Intro to Wide Range of VX',
        fileName: 'WideRangeOfVXIntro',
      },
      { text: 'powered', sceneName: 'Intro to Wide Range of VX', fileName: 'WideRangeOfVXIntro' },
      {
        text: 'experience',
        sceneName: 'Intro to Wide Range of VX',
        fileName: 'WideRangeOfVXIntro',
      },
      { text: 'audience', sceneName: 'Intro to Wide Range of VX', fileName: 'WideRangeOfVXIntro' },
      { text: 'channel', sceneName: 'Intro to Wide Range of VX', fileName: 'WideRangeOfVXIntro' },
      { text: 'viewer', sceneName: 'Intro to Wide Range of VX', fileName: 'WideRangeOfVXIntro' },
      { text: 'wide', sceneName: 'Intro to Wide Range of VX', fileName: 'WideRangeOfVXIntro' },
      { text: 'video', sceneName: 'Intro to Wide Range of VX', fileName: 'WideRangeOfVXIntro' },
      { text: 'intro', sceneName: 'Intro to Wide Range of VX', fileName: 'WideRangeOfVXIntro' },
      {
        text: 'introduction',
        sceneName: 'Intro to Wide Range of VX',
        fileName: 'WideRangeOfVXIntro',
      },
      {
        text: 'interactive',
        sceneName: 'Intro to Wide Range of VX',
        fileName: 'WideRangeOfVXIntro',
      },
      {
        text: 'contextual',
        sceneName: 'Intro to Wide Range of VX',
        fileName: 'WideRangeOfVXIntro',
      },
      {
        text: 'dynamic',
        sceneName: 'Intro to Wide Range of VX',
        fileName: 'WideRangeOfVXIntro',
      },
      {
        text: 'data',
        sceneName: 'Intro to Wide Range of VX',
        fileName: 'WideRangeOfVXIntro',
      },
      // Individualized VX -- Individualized
      { text: 'individual', sceneName: 'Individualized VX', fileName: 'Individualized' },
      { text: 'targeting', sceneName: 'Individualized VX', fileName: 'Individualized' },
      { text: 'personalized', sceneName: 'Individualized VX', fileName: 'Individualized' },
      { text: 'video', sceneName: 'Individualized VX', fileName: 'Individualized' },
      { text: 'relevant', sceneName: 'Individualized VX', fileName: 'Individualized' },
      { text: 'relevance', sceneName: 'Individualized VX', fileName: 'Individualized' },
      { text: 'data', sceneName: 'Individualized VX', fileName: 'Individualized' },
      { text: 'real', sceneName: 'Individualized VX', fileName: 'Individualized' },
      { text: 'time', sceneName: 'Individualized VX', fileName: 'Individualized' },
      { text: 'real time', sceneName: 'Individualized VX', fileName: 'Individualized' },
      // Contextual VX - Contextual
      { text: 'context', sceneName: 'Contextual VX', fileName: 'Contextual' },
      { text: 'contextual', sceneName: 'Contextual VX', fileName: 'Contextual' },
      { text: 'video', sceneName: 'Contextual VX', fileName: 'Contextual' },
      { text: 'relevant', sceneName: 'Contextual VX', fileName: 'Contextual' },
      { text: 'relevance', sceneName: 'Contextual VX', fileName: 'Contextual' },
      { text: 'data', sceneName: 'Contextual VX', fileName: 'Contextual' },
      { text: 'channel', sceneName: 'Contextual VX', fileName: 'Contextual' },
      { text: 'static', sceneName: 'Contextual VX', fileName: 'Contextual' },
      { text: 'segmented', sceneName: 'Contextual VX', fileName: 'Contextual' },
      { text: 'audience', sceneName: 'Contextual VX', fileName: 'Contextual' },
      // Interactive VX -- Interactive
      { text: 'interactive', sceneName: 'Interactive VX', fileName: 'Interactive' },
      { text: 'chaptering', sceneName: 'Interactive VX', fileName: 'Interactive' },
      { text: 'video', sceneName: 'Interactive VX', fileName: 'Interactive' },
      { text: 'relevant', sceneName: 'Interactive VX', fileName: 'Interactive' },
      { text: 'relevance', sceneName: 'Interactive VX', fileName: 'Interactive' },
      // Dynamic VX -- Dynamic
      { text: 'dynamic', sceneName: 'Dynamic VX', fileName: 'Dynamic' },
      { text: 'video', sceneName: 'Dynamic VX', fileName: 'Dynamic' },
      { text: 'relvant', sceneName: 'Dynamic VX', fileName: 'Dynamic' },
      { text: 'relevance', sceneName: 'Dynamic VX', fileName: 'Dynamic' },
      { text: 'advertising', sceneName: 'Dynamic VX', fileName: 'Dynamic' },
      { text: 'ads', sceneName: 'Dynamic VX', fileName: 'Dynamic' },
      { text: 'paid media', sceneName: 'Dynamic VX', fileName: 'Dynamic' },
      { text: 'paid', sceneName: 'Dynamic VX', fileName: 'Dynamic' },
      { text: 'media', sceneName: 'Dynamic VX', fileName: 'Dynamic' },
      // //*** VX Platform -- VXPlatform
      // // Platform Overview -- PlatformOverview
      { text: 'platform', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'VX', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'software', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'technology', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'capabilities', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'features', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'studio', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'overview', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'dashboards', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'analytics', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'player', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'toolkit', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'user', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'messaging', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'manage', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'implement', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'buy', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'license', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'get started', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'get', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'started', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'scale', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'strategy', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'components', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'tools', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'UI', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'user interface', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'user', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'interface', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'product', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'delivery', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'enablement', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'user', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      { text: 'users', sceneName: 'Platform Overview', fileName: 'PlatformOverview' },
      // // Framework -- Framework
      { text: 'studio', sceneName: 'Framework', fileName: 'Framework' },
      { text: 'framework', sceneName: 'Framework', fileName: 'Framework' },
      { text: 'experience', sceneName: 'Framework', fileName: 'Framework' },
      { text: 'map', sceneName: 'Framework', fileName: 'Framework' },
      { text: 'story', sceneName: 'Framework', fileName: 'Framework' },
      { text: 'strategy', sceneName: 'Framework', fileName: 'Framework' },
      { text: 'planning', sceneName: 'Framework', fileName: 'Framework' },
      { text: 'audience', sceneName: 'Framework', fileName: 'Framework' },
      { text: 'reach', sceneName: 'Framework', fileName: 'Framework' },
      { text: 'data', sceneName: 'Framework', fileName: 'Framework' },
      { text: 'story', sceneName: 'Framework', fileName: 'Framework' },
      { text: 'touchpoint', sceneName: 'Framework', fileName: 'Framework' },
      { text: 'journey', sceneName: 'Framework', fileName: 'Framework' },
      { text: 'goals', sceneName: 'Framework', fileName: 'Framework' },
      { text: 'KPI', sceneName: 'Framework', fileName: 'Framework' },
      // // Designer -- Designer
      { text: 'studio', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'adobe', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'AfterEffects', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'placeholder', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'place holder', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'place', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'holder', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'designer', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'create', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'design', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'animate', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'narration', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'smart timing', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'smart', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'timing', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'flexible', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'visual', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'animation', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'motion graphics', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'motion', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'graphics', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'author', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'create', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'creative', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'modularity', sceneName: 'Designer', fileName: 'Designer' },
      { text: 'modular', sceneName: 'Designer', fileName: 'Designer' },
      // Builder -- Builder
      { text: 'studio', sceneName: 'Builder', fileName: 'Builder' },
      { text: 'builder', sceneName: 'Builder', fileName: 'Builder' },
      { text: 'logic', sceneName: 'Builder', fileName: 'Builder' },
      { text: 'data', sceneName: 'Builder', fileName: 'Builder' },
      { text: 'placeholder', sceneName: 'Builder', fileName: 'Builder' },
      { text: 'place holder', sceneName: 'Builder', fileName: 'Builder' },
      { text: 'place', sceneName: 'Builder', fileName: 'Builder' },
      { text: 'holder', sceneName: 'Builder', fileName: 'Builder' },
      { text: 'content', sceneName: 'Builder', fileName: 'Builder' },
      { text: 'variation', sceneName: 'Builder', fileName: 'Builder' },
      { text: 'assets', sceneName: 'Builder', fileName: 'Builder' },
      { text: 'narration', sceneName: 'Builder', fileName: 'Builder' },
      { text: 'personalize', sceneName: 'Builder', fileName: 'Builder' },
      { text: 'author', sceneName: 'Builder', fileName: 'Builder' },
      { text: 'create', sceneName: 'Builder', fileName: 'Builder' },
      { text: 'CMS', sceneName: 'Builder', fileName: 'Builder' },
      { text: 'modularity', sceneName: 'Builder', fileName: 'Builder' },
      { text: 'modular', sceneName: 'Builder', fileName: 'Builder' },
      // Engine -- Engine
      { text: 'engine', sceneName: 'Engine', fileName: 'Engine' },
      { text: 'render', sceneName: 'Engine', fileName: 'Engine' },
      { text: 'gpu', sceneName: 'Engine', fileName: 'Engine' },
      { text: 'cpu', sceneName: 'Engine', fileName: 'Engine' },
      { text: 'real time', sceneName: 'Engine', fileName: 'Engine' },
      { text: 'real', sceneName: 'Engine', fileName: 'Engine' },
      { text: 'time', sceneName: 'Engine', fileName: 'Engine' },
      { text: 'data', sceneName: 'Engine', fileName: 'Engine' },
      { text: 'delivery', sceneName: 'Engine', fileName: 'Engine' },
      // DSP -- DSP
      { text: 'DSP', sceneName: 'DSP', fileName: 'DSP' },
      { text: 'demand side platform', sceneName: 'DSP', fileName: 'DSP' },
      { text: 'demand', sceneName: 'DSP', fileName: 'DSP' },
      { text: 'side', sceneName: 'DSP', fileName: 'DSP' },
      { text: 'platform', sceneName: 'DSP', fileName: 'DSP' },
      { text: 'ads', sceneName: 'DSP', fileName: 'DSP' },
      { text: 'advertising', sceneName: 'DSP', fileName: 'DSP' },
      { text: 'velocity', sceneName: 'DSP', fileName: 'DSP' },
      { text: 'paid', sceneName: 'DSP', fileName: 'DSP' },
      { text: 'prospecting', sceneName: 'DSP', fileName: 'DSP' },
      { text: 'retargeting', sceneName: 'DSP', fileName: 'DSP' },
      { text: 'acquisition', sceneName: 'DSP', fileName: 'DSP' },
      { text: 'ROAS', sceneName: 'DSP', fileName: 'DSP' },
      { text: 'conversion', sceneName: 'DSP', fileName: 'DSP' },
      { text: 'funnel', sceneName: 'DSP', fileName: 'DSP' },
      { text: 'CPO', sceneName: 'DSP', fileName: 'DSP' },
      { text: 'shopper', sceneName: 'DSP', fileName: 'DSP' },
      { text: 'buyer', sceneName: 'DSP', fileName: 'DSP' },
      { text: 'inventory', sceneName: 'DSP', fileName: 'DSP' },
      //*** VX for Advertising -- VXforAds
      // The Advertising Challenge -- TheAdvertisingChallenge
      { text: 'ads', sceneName: 'The Advertising Challenge', fileName: 'TheAdvertisingChallenge' },
      {
        text: 'advertising',
        sceneName: 'The Advertising Challenge',
        fileName: 'TheAdvertisingChallenge',
      },
      {
        text: 'targeting',
        sceneName: 'The Advertising Challenge',
        fileName: 'TheAdvertisingChallenge',
      },
      {
        text: 'audience',
        sceneName: 'The Advertising Challenge',
        fileName: 'TheAdvertisingChallenge',
      },
      {
        text: 'engage',
        sceneName: 'The Advertising Challenge',
        fileName: 'TheAdvertisingChallenge',
      },
      {
        text: 'consumers',
        sceneName: 'The Advertising Challenge',
        fileName: 'TheAdvertisingChallenge',
      },
      {
        text: 'shoppers',
        sceneName: 'The Advertising Challenge',
        fileName: 'TheAdvertisingChallenge',
      },
      {
        text: 'e-commerce',
        sceneName: 'The Advertising Challenge',
        fileName: 'TheAdvertisingChallenge',
      },
      {
        text: 'retail',
        sceneName: 'The Advertising Challenge',
        fileName: 'TheAdvertisingChallenge',
      },
      {
        text: 'digital',
        sceneName: 'The Advertising Challenge',
        fileName: 'TheAdvertisingChallenge',
      },
      {
        text: 'brands',
        sceneName: 'The Advertising Challenge',
        fileName: 'TheAdvertisingChallenge',
      },
      { text: 'ROAS', sceneName: 'The Advertising Challenge', fileName: 'TheAdvertisingChallenge' },
      {
        text: 'conversion',
        sceneName: 'The Advertising Challenge',
        fileName: 'TheAdvertisingChallenge',
      },
      {
        text: 'retargeting',
        sceneName: 'The Advertising Challenge',
        fileName: 'TheAdvertisingChallenge',
      },
      {
        text: 'prospecting',
        sceneName: 'The Advertising Challenge',
        fileName: 'TheAdvertisingChallenge',
      },
      {
        text: 'connect',
        sceneName: 'The Advertising Challenge',
        fileName: 'TheAdvertisingChallenge',
      },
      {
        text: 'aquisition',
        sceneName: 'The Advertising Challenge',
        fileName: 'TheAdvertisingChallenge',
      },
      {
        text: 'aquire',
        sceneName: 'The Advertising Challenge',
        fileName: 'TheAdvertisingChallenge',
      },
      {
        text: 'convert',
        sceneName: 'The Advertising Challenge',
        fileName: 'TheAdvertisingChallenge',
      },
      {
        text: 'retain',
        sceneName: 'The Advertising Challenge',
        fileName: 'TheAdvertisingChallenge',
      },
      {
        text: 'loyal',
        sceneName: 'The Advertising Challenge',
        fileName: 'TheAdvertisingChallenge',
      },
      {
        text: 'agency',
        sceneName: 'The Advertising Challenge',
        fileName: 'TheAdvertisingChallenge',
      },
      // //*** The Editor -- Editor
      // // Plan Your Strategy -- Plan
      { text: 'plan', sceneName: 'Plan Your Strategy', fileName: 'Plan' },
      { text: 'planning', sceneName: 'Plan Your Strategy', fileName: 'Plan' },
      { text: 'strategy', sceneName: 'Plan Your Strategy', fileName: 'Plan' },
      { text: 'pre-built solutions', sceneName: 'Plan Your Strategy', fileName: 'Plan' },
      { text: 'pre-built', sceneName: 'Plan Your Strategy', fileName: 'Plan' },
      { text: 'solutions', sceneName: 'Plan Your Strategy', fileName: 'Plan' },
      { text: 'industry library', sceneName: 'Plan Your Strategy', fileName: 'Plan' },
      { text: 'industry solutions', sceneName: 'Plan Your Strategy', fileName: 'Plan' },
      { text: 'industry', sceneName: 'Plan Your Strategy', fileName: 'Plan' },
      { text: 'library', sceneName: 'Plan Your Strategy', fileName: 'Plan' },
      { text: 'editor', sceneName: 'Plan Your Strategy', fileName: 'Plan' },
      // // Create Your VX -- Create
      { text: 'create', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'vx', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'templates', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'scene templates', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'scene', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'scenes', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'scene template library', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'library', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'theme', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'template theme', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'template library', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'assets', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'data', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'data sources', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'sources', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'narrations', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'colors', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'fonts', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'logo', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'paid channels', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'paid', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'owned channels', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'owned', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'channels', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'personalization', sceneName: 'Create Your VX', fileName: 'Create' },
      { text: 'editor', sceneName: 'Create Your VX', fileName: 'Create' },
      // // Scale Across Your CX -- Reuse
      { text: 'scale', sceneName: 'Scale Across Your CX', fileName: 'Scale' },
      { text: 'real-time rendering', sceneName: 'Scale Across Your CX', fileName: 'Scale' },
      { text: 'real-time', sceneName: 'Scale Across Your CX', fileName: 'Scale' },
      { text: 'rendering', sceneName: 'Scale Across Your CX', fileName: 'Scale' },
      { text: 'render', sceneName: 'Scale Across Your CX', fileName: 'Scale' },
      { text: 'cx', sceneName: 'Scale Across Your CX', fileName: 'Scale' },
      { text: 'reuse', sceneName: 'Scale Across Your CX', fileName: 'Scale' },
      { text: 'editor', sceneName: 'Scale Across Your CX', fileName: 'Scale' },
      // // Analyze Performance -- Analyze
      { text: 'analyze', sceneName: 'Analyze Performance', fileName: 'Analyze' },
      { text: 'performance', sceneName: 'Analyze Performance', fileName: 'Analyze' },
      { text: 'measurement', sceneName: 'Analyze Performance', fileName: 'Analyze' },
      { text: 'analytics', sceneName: 'Analyze Performance', fileName: 'Analyze' },
      { text: 'dashboards', sceneName: 'Analyze Performance', fileName: 'Analyze' },
      { text: 'ai', sceneName: 'Analyze Performance', fileName: 'Analyze' },
      { text: 'artificial intelligence', sceneName: 'Analyze Performance', filefileName: 'Analyze' },
      { text: 'artificial', sceneName: 'Analyze Performance', filefileName: 'Analyze' },
      { text: 'intelligence', sceneName: 'Analyze Performance', filefileName: 'Analyze' },
      { text: 'KPI', sceneName: 'Analyze Performance', filefileName: 'Analyze' },
      { text: 'editor', sceneName: 'Analyze Performance', filefileName: 'Analyze' },
    ],
  },
  players: { players: null, activePlayer: 1 },
};
