export const storiesInfo = {
  '1': {
    nameForHfe: 'WhyVXMatters',
    title: 'Why VX matters',
    DEV_MODE: false,
    action: 'Watch next',
    fallbackSession:
      'ewogICAicHJvamVjdGlkIjogInZweC9TdG9yeSIsCiAgICJfbGlua3MiOiB7CiAgICAgICJ2aWRlby1zdHJlYW0iOiBbCiAgICAgICAgIHsKICAgICAgICAgICAgImhyZWYiOiAiaHR0cHM6Ly9wbGF5ZXIuc3VuZGF5c2t5LmNvbS9jdXN0b21lcnMvbWFzdGVycGllY2UvZmFsbGJhY2tzL1doeVZYTWF0dGVycy92aWRlby5tcDQiLAogICAgICAgICAgICAiaWQiOiAidmlkZW8iLAogICAgICAgICAgICAibWVkaWEtdHlwZSI6ICJ2aWRlby9tcDQiCiAgICAgICAgIH0KICAgICAgXSwKICAgICAgIm1ldGFkYXRhIjogewogICAgICAgICAiaHJlZiI6ICJodHRwczovL3BsYXllci5zdW5kYXlza3kuY29tL2N1c3RvbWVycy9tYXN0ZXJwaWVjZS9mYWxsYmFja3MvV2h5VlhNYXR0ZXJzL21ldGFkYXRhLmpzb24iCiAgICAgIH0sCiAgICAgICJ0cmFuc2NyaXB0IjogewogICAgICAgICAiaHJlZiI6ICJodHRwczovL3BsYXllci5zdW5kYXlza3kuY29tL2N1c3RvbWVycy9tYXN0ZXJwaWVjZS9mYWxsYmFja3MvV2h5VlhNYXR0ZXJzL3RyYW5zY3JpcHQudnR0IgogICAgICB9LAogICAgICAic2VsZiI6IHsKICAgICAgICAgImhyZWYiOiAiaHR0cHM6Ly9wbGF5ZXIuc3VuZGF5c2t5LmNvbS9jdXN0b21lcnMvbWFzdGVycGllY2UvZmFsbGJhY2tzL1doeVZYTWF0dGVycy9zdGF0dXMuanNvbiIKICAgICAgfSwKICAgICAgImNhcHRpb25zIjogewogICAgICAgICAiaHJlZiI6ICJodHRwczovL3BsYXllci5zdW5kYXlza3kuY29tL2N1c3RvbWVycy9tYXN0ZXJwaWVjZS9mYWxsYmFja3MvV2h5VlhNYXR0ZXJzL3ZpZGVvLnZ0dCIKICAgICAgfQogICB9Cn0=',
    scenes: [
      {
        name: 'Opening',
        title: 'Introduction',
        timeStamp: 0,
        timeStampEnd: 23.68,
        display: true,
      },
      {
        name: 'TheCXChallenge',
        title: 'The CX Challenge',
        timeStamp: 23.68,
        timeStampEnd: 59.8,
        display: true,
      },
      {
        name: 'SSkyValueProp',
        title: 'Why SundaySky',
        timeStamp: 59.8,
        timeStampEnd: 73.12,
        display: true,
      },
      {
        name: 'FourMoments',
        title: 'Moments in the Customer Journey',
        timeStamp: 73.12,
        timeStampEnd: 114.08,
        display: true,
      },
      {
        name: 'TheValueOfVX',
        title: 'Outcomes & ROI',
        timeStamp: 114.08,
        timeStampEnd: 129.12,
        display: true,
      },
      {
        name: 'CaseStudy',
        title: 'Case Study',
        timeStamp: 129.12,
        timeStampEnd: 142.88,
        display: true,
      },
      {
        name: 'CTA',
        title: 'CTA',
        timeStamp: 142.88,
        timeStampEnd: 160.36,
        display: false,
      },
    ],
  },
  '2': {
    nameForHfe: 'HowVXWorks',
    title: 'VX Modularity',
    DEV_MODE: false,
    action: 'Watch next',
    fallbackSession:
      'ewogICAicHJvamVjdGlkIjogInZweC9TdG9yeSIsCiAgICJfbGlua3MiOiB7CiAgICAgICJ2aWRlby1zdHJlYW0iOiBbCiAgICAgICAgIHsKICAgICAgICAgICAgImhyZWYiOiAiaHR0cHM6Ly9wbGF5ZXIuc3VuZGF5c2t5LmNvbS9jdXN0b21lcnMvbWFzdGVycGllY2UvZmFsbGJhY2tzL0hvd1ZYV29ya3MvdmlkZW8ubXA0IiwKICAgICAgICAgICAgImlkIjogInZpZGVvIiwKICAgICAgICAgICAgIm1lZGlhLXR5cGUiOiAidmlkZW8vbXA0IgogICAgICAgICB9CiAgICAgIF0sCiAgICAgICJtZXRhZGF0YSI6IHsKICAgICAgICAgImhyZWYiOiAiaHR0cHM6Ly9wbGF5ZXIuc3VuZGF5c2t5LmNvbS9jdXN0b21lcnMvbWFzdGVycGllY2UvZmFsbGJhY2tzL0hvd1ZYV29ya3MvbWV0YWRhdGEuanNvbiIKICAgICAgfSwKICAgICAgInRyYW5zY3JpcHQiOiB7CiAgICAgICAgICJocmVmIjogImh0dHBzOi8vcGxheWVyLnN1bmRheXNreS5jb20vY3VzdG9tZXJzL21hc3RlcnBpZWNlL2ZhbGxiYWNrcy9Ib3dWWFdvcmtzL3RyYW5zY3JpcHQudnR0IgogICAgICB9LAogICAgICAic2VsZiI6IHsKICAgICAgICAgImhyZWYiOiAiaHR0cHM6Ly9wbGF5ZXIuc3VuZGF5c2t5LmNvbS9jdXN0b21lcnMvbWFzdGVycGllY2UvZmFsbGJhY2tzL0hvd1ZYV29ya3Mvc3RhdHVzLmpzb24iCiAgICAgIH0sCiAgICAgICJjYXB0aW9ucyI6IHsKICAgICAgICAgImhyZWYiOiAiaHR0cHM6Ly9wbGF5ZXIuc3VuZGF5c2t5LmNvbS9jdXN0b21lcnMvbWFzdGVycGllY2UvZmFsbGJhY2tzL0hvd1ZYV29ya3MvdmlkZW8udnR0IgogICAgICB9CiAgIH0KfQ==',
    scenes: [
      {
        name: 'Opening',
        title: 'Introduction',
        timeStamp: 0,
        timeStampEnd: 23.52,
        display: true,
      },
      {
        name: 'TenetsOfVX',
        title: 'VX Capabilities',
        timeStamp: 23.52,
        timeStampEnd: 46.04,
        display: true,
      },
      {
        name: 'SceneModularity',
        title: 'Scene Modularity',
        timeStamp: 46.04,
        timeStampEnd: 68.84,
        display: true,
      },
      {
        name: 'SceneDynamics',
        title: 'Scene Flexibility',
        timeStamp: 68.84,
        timeStampEnd: 89.8,
        display: true,
      },
      {
        name: 'SceneLibrary',
        title: 'Scene Library',
        timeStamp: 89.8,
        timeStampEnd: 114.64,
        display: true,
      },
      {
        name: 'DeliveryChannels',
        title: 'Delivery Channels',
        timeStamp: 114.64,
        timeStampEnd: 134.56,
        display: true,
      },
      {
        name: 'CTA',
        title: 'CTA',
        timeStamp: 134.56,
        timeStampEnd: 151.72,
        display: false,
      },
    ],
  },
  '3': {
    nameForHfe: 'WhatDefinesVX',
    title: 'What defines VX',
    DEV_MODE: false,
    action: 'Watch next',
    fallbackSession:
      'ewogICAicHJvamVjdGlkIjogInZweC9TdG9yeSIsCiAgICJfbGlua3MiOiB7CiAgICAgICJ2aWRlby1zdHJlYW0iOiBbCiAgICAgICAgIHsKICAgICAgICAgICAgImhyZWYiOiAiaHR0cHM6Ly9wbGF5ZXIuc3VuZGF5c2t5LmNvbS9jdXN0b21lcnMvbWFzdGVycGllY2UvZmFsbGJhY2tzL1doYXREZWZpbmVzVlgvdmlkZW8ubXA0IiwKICAgICAgICAgICAgImlkIjogInZpZGVvIiwKICAgICAgICAgICAgIm1lZGlhLXR5cGUiOiAidmlkZW8vbXA0IgogICAgICAgICB9CiAgICAgIF0sCiAgICAgICJtZXRhZGF0YSI6IHsKICAgICAgICAgImhyZWYiOiAiaHR0cHM6Ly9wbGF5ZXIuc3VuZGF5c2t5LmNvbS9jdXN0b21lcnMvbWFzdGVycGllY2UvZmFsbGJhY2tzL1doYXREZWZpbmVzVlgvbWV0YWRhdGEuanNvbiIKICAgICAgfSwKICAgICAgInRyYW5zY3JpcHQiOiB7CiAgICAgICAgICJocmVmIjogImh0dHBzOi8vcGxheWVyLnN1bmRheXNreS5jb20vY3VzdG9tZXJzL21hc3RlcnBpZWNlL2ZhbGxiYWNrcy9XaGF0RGVmaW5lc1ZYL3RyYW5zY3JpcHQudnR0IgogICAgICB9LAogICAgICAic2VsZiI6IHsKICAgICAgICAgImhyZWYiOiAiaHR0cHM6Ly9wbGF5ZXIuc3VuZGF5c2t5LmNvbS9jdXN0b21lcnMvbWFzdGVycGllY2UvZmFsbGJhY2tzL1doYXREZWZpbmVzVlgvc3RhdHVzLmpzb24iCiAgICAgIH0sCiAgICAgICJjYXB0aW9ucyI6IHsKICAgICAgICAgImhyZWYiOiAiaHR0cHM6Ly9wbGF5ZXIuc3VuZGF5c2t5LmNvbS9jdXN0b21lcnMvbWFzdGVycGllY2UvZmFsbGJhY2tzL1doYXREZWZpbmVzVlgvdmlkZW8udnR0IgogICAgICB9CiAgIH0KfQ==',
    scenes: [
      {
        name: 'TenetsOfVX',
        title: 'VX Capabilities',
        timeStamp: 0,
        timeStampEnd: 22.52,
        display: true,
      },
      {
        name: 'WideRangeOfVXIntro',
        title: 'Intro to Wide Range of VX',
        timeStamp: 22.52,
        timeStampEnd: 38.8,
        display: true,
      },
      {
        name: 'Individualized',
        title: 'Individualized VX',
        timeStamp: 38.8,
        timeStampEnd: 51.48,
        display: true,
      },
      {
        name: 'Contextual',
        title: 'Contextual VX',
        timeStamp: 51.48,
        timeStampEnd: 63.24,
        display: true,
      },
      {
        name: 'Interactive',
        title: 'Interactive VX',
        timeStamp: 63.24,
        timeStampEnd: 75.84,
        display: true,
      },
      {
        name: 'Dynamic',
        title: 'Dynamic VX',
        timeStamp: 75.84,
        timeStampEnd: 91.76,
        display: true,
      },
      {
        name: 'CTA',
        title: 'CTA',
        timeStamp: 91.76,
        timeStampEnd: 108.92,
        display: false,
      },
    ],
  },
  '4': {
    nameForHfe: 'VXPlatform',
    title: 'VX Platform',
    DEV_MODE: false,
    action: 'Watch next',
    fallbackSession:
      'ewogICAicHJvamVjdGlkIjogInZweC9TdG9yeSIsCiAgICJfbGlua3MiOiB7CiAgICAgICJ2aWRlby1zdHJlYW0iOiBbCiAgICAgICAgIHsKICAgICAgICAgICAgImhyZWYiOiAiaHR0cHM6Ly9wbGF5ZXIuc3VuZGF5c2t5LmNvbS9jdXN0b21lcnMvbWFzdGVycGllY2UvZmFsbGJhY2tzL1ZYUGxhdGZvcm0vdmlkZW8ubXA0IiwKICAgICAgICAgICAgImlkIjogInZpZGVvIiwKICAgICAgICAgICAgIm1lZGlhLXR5cGUiOiAidmlkZW8vbXA0IgogICAgICAgICB9CiAgICAgIF0sCiAgICAgICJtZXRhZGF0YSI6IHsKICAgICAgICAgImhyZWYiOiAiaHR0cHM6Ly9wbGF5ZXIuc3VuZGF5c2t5LmNvbS9jdXN0b21lcnMvbWFzdGVycGllY2UvZmFsbGJhY2tzL1ZYUGxhdGZvcm0vbWV0YWRhdGEuanNvbiIKICAgICAgfSwKICAgICAgInRyYW5zY3JpcHQiOiB7CiAgICAgICAgICJocmVmIjogImh0dHBzOi8vcGxheWVyLnN1bmRheXNreS5jb20vY3VzdG9tZXJzL21hc3RlcnBpZWNlL2ZhbGxiYWNrcy9WWFBsYXRmb3JtL3RyYW5zY3JpcHQudnR0IgogICAgICB9LAogICAgICAic2VsZiI6IHsKICAgICAgICAgImhyZWYiOiAiaHR0cHM6Ly9wbGF5ZXIuc3VuZGF5c2t5LmNvbS9jdXN0b21lcnMvbWFzdGVycGllY2UvZmFsbGJhY2tzL1ZYUGxhdGZvcm0vc3RhdHVzLmpzb24iCiAgICAgIH0sCiAgICAgICJjYXB0aW9ucyI6IHsKICAgICAgICAgImhyZWYiOiAiaHR0cHM6Ly9wbGF5ZXIuc3VuZGF5c2t5LmNvbS9jdXN0b21lcnMvbWFzdGVycGllY2UvZmFsbGJhY2tzL1ZYUGxhdGZvcm0vdmlkZW8udnR0IgogICAgICB9CiAgIH0KfQ==',
    scenes: [
      {
        name: 'Opening',
        title: 'Introduction',
        timeStamp: 0,
        timeStampEnd: 27.04,
        display: true,
      },
      {
        name: 'TenetsOfVX',
        title: 'VX Capabilities',
        timeStamp: 27.04,
        timeStampEnd: 49.56,
        display: true,
      },
      {
        name: 'PlatformOverview',
        title: 'Platform Overview',
        timeStamp: 49.56,
        timeStampEnd: 72.16,
        display: true,
      },
      {
        name: 'Framework',
        title: 'Framework',
        timeStamp: 72.16,
        timeStampEnd: 95.08,
        display: true,
      },
      {
        name: 'Designer',
        title: 'Designer',
        timeStamp: 95.08,
        timeStampEnd: 114.72,
        display: true,
      },
      {
        name: 'Builder',
        title: 'Builder',
        timeStamp: 114.72,
        timeStampEnd: 143.28,
        display: true,
      },
      {
        name: 'Engine',
        title: 'Engine',
        timeStamp: 143.28,
        timeStampEnd: 165.28,
        display: true,
      },
      {
        name: 'DSP',
        title: 'DSP',
        timeStamp: 165.28,
        timeStampEnd: 188.4,
        display: true,
      },
      {
        name: 'CTA',
        title: 'CTA',
        timeStamp: 188.4,
        timeStampEnd: 203.52,
        display: false,
      },
    ],
  },
  '5': {
    nameForHfe: 'VXforAds',
    title: 'VX Advertising',
    DEV_MODE: false,
    action: 'Watch next',
    fallbackSession:
      'ewogICAicHJvamVjdGlkIjogInZweC9TdG9yeSIsCiAgICJfbGlua3MiOiB7CiAgICAgICJ2aWRlby1zdHJlYW0iOiBbCiAgICAgICAgIHsKICAgICAgICAgICAgImhyZWYiOiAiaHR0cHM6Ly9wbGF5ZXIuc3VuZGF5c2t5LmNvbS9jdXN0b21lcnMvbWFzdGVycGllY2UvZmFsbGJhY2tzL1ZYZm9yQWRzL3ZpZGVvLm1wNCIsCiAgICAgICAgICAgICJpZCI6ICJ2aWRlbyIsCiAgICAgICAgICAgICJtZWRpYS10eXBlIjogInZpZGVvL21wNCIKICAgICAgICAgfQogICAgICBdLAogICAgICAibWV0YWRhdGEiOiB7CiAgICAgICAgICJocmVmIjogImh0dHBzOi8vcGxheWVyLnN1bmRheXNreS5jb20vY3VzdG9tZXJzL21hc3RlcnBpZWNlL2ZhbGxiYWNrcy9WWGZvckFkcy9tZXRhZGF0YS5qc29uIgogICAgICB9LAogICAgICAidHJhbnNjcmlwdCI6IHsKICAgICAgICAgImhyZWYiOiAiaHR0cHM6Ly9wbGF5ZXIuc3VuZGF5c2t5LmNvbS9jdXN0b21lcnMvbWFzdGVycGllY2UvZmFsbGJhY2tzL1ZYZm9yQWRzL3RyYW5zY3JpcHQudnR0IgogICAgICB9LAogICAgICAic2VsZiI6IHsKICAgICAgICAgImhyZWYiOiAiaHR0cHM6Ly9wbGF5ZXIuc3VuZGF5c2t5LmNvbS9jdXN0b21lcnMvbWFzdGVycGllY2UvZmFsbGJhY2tzL1ZYZm9yQWRzL3N0YXR1cy5qc29uIgogICAgICB9LAogICAgICAiY2FwdGlvbnMiOiB7CiAgICAgICAgICJocmVmIjogImh0dHBzOi8vcGxheWVyLnN1bmRheXNreS5jb20vY3VzdG9tZXJzL21hc3RlcnBpZWNlL2ZhbGxiYWNrcy9WWGZvckFkcy92aWRlby52dHQiCiAgICAgIH0KICAgfQp9',
    scenes: [
      {
        name: 'Opening',
        title: 'Introduction',
        timeStamp: 0,
        timeStampEnd: 28.84,
        display: true,
      },
      {
        name: 'TheAdvertisingChallenge',
        title: 'The Advertising Challenge',
        timeStamp: 28.84,
        timeStampEnd: 66.08,
        display: true,
      },
      {
        name: 'TenetsOfVX',
        title: 'VX Capabilities',
        timeStamp: 66.08,
        timeStampEnd: 88.6,
        display: true,
      },
      {
        name: 'SceneDynamics',
        title: 'Scene Flexibility',
        timeStamp: 88.6,
        timeStampEnd: 116.16,
        display: true,
      },
      {
        name: 'DeliveryChannels',
        title: 'Delivery Channels',
        timeStamp: 116.16,
        timeStampEnd: 138.6,
        display: true,
      },
      {
        name: 'DSP',
        title: 'DSP',
        timeStamp: 138.6,
        timeStampEnd: 161.72,
        display: true,
      },
      {
        name: 'TheValueOfVX',
        title: 'Outcomes & ROI',
        timeStamp: 161.72,
        timeStampEnd: 176.04,
        display: true,
      },
      {
        name: 'CTA',
        title: 'CTA',
        timeStamp: 176.04,
        timeStampEnd: 193.2,
        display: false,
      },
    ],
  },
  '0': {
    nameForHfe: 'SO',
    title: 'VX Chapters',
    DEV_MODE: false,
    action: 'Watch next',
    scenes: [
      {
        name: 'Opening',
        title: 'Introduction',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'TheCXChallenge',
        title: 'The CX Challenge',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'SSkyValueProp',
        title: 'Why SundaySky',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'FourMoments',
        title: 'Moments in the Customer Journey',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'TheValueOfVX',
        title: 'Outcomes & ROI',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'CaseStudy',
        title: 'Case Study',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'TenetsOfVX',
        title: 'VX Capabilities',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'SceneModularity',
        title: 'Scene Modularity',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'SceneDynamics',
        title: 'Scene Flexibility',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'SceneLibrary',
        title: 'Scene Library',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'DeliveryChannels',
        title: 'Delivery Channels',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'WideRangeOfVXIntro',
        title: 'Intro to Wide Range of VX',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'Individualized',
        title: 'Individualized VX',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'Contextual',
        title: 'Contextual VX',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'Interactive',
        title: 'Interactive VX',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'Dynamic',
        title: 'Dynamic VX',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'PlatformOverview',
        title: 'Platform Overview',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'Framework',
        title: 'Framework',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'Designer',
        title: 'Designer',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'Builder',
        title: 'Builder',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'Engine',
        title: 'Engine',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'DSP',
        title: 'DSP',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'TheAdvertisingChallenge',
        title: 'The Advertising Challenge',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'DSP',
        title: 'DSP',
        timeStamp: 0,
        timeStampEnd: 0,
        display: true,
      },
      {
        name: 'CTA',
        title: 'CTA',
        timeStamp: 0,
        timeStampEnd: 0,
        display: false,
      },
    ],
  },
  '6': {
    nameForHfe: 'Acquia',
    title: 'The Power of Acquia & VX',
    DEV_MODE: true,
    action: 'Watch next',
    fallbackSession:
        'ewogICAicHJvamVjdGlkIjogInZweC9TdG9yeSIsCiAgICJfbGlua3MiOiB7CiAgICAgICJ2aWRlby1zdHJlYW0iOiBbCiAgICAgICAgIHsKICAgICAgICAgICAgImhyZWYiOiAiaHR0cHM6Ly9wbGF5ZXIuc3VuZGF5c2t5LmNvbS9jdXN0b21lcnMvbWFzdGVycGllY2UvZmFsbGJhY2tzL0FxY3VpYS92aWRlby5tcDQiLAogICAgICAgICAgICAiaWQiOiAidmlkZW8iLAogICAgICAgICAgICAibWVkaWEtdHlwZSI6ICJ2aWRlby9tcDQiCiAgICAgICAgIH0KICAgICAgXSwKICAgICAgIm1ldGFkYXRhIjogewogICAgICAgICAiaHJlZiI6ICJodHRwczovL3BsYXllci5zdW5kYXlza3kuY29tL2N1c3RvbWVycy9tYXN0ZXJwaWVjZS9mYWxsYmFja3MvQXFjdWlhL21ldGFkYXRhLmpzb24iCiAgICAgIH0sCiAgICAgICJ0cmFuc2NyaXB0IjogewogICAgICAgICAiaHJlZiI6ICJodHRwczovL3BsYXllci5zdW5kYXlza3kuY29tL2N1c3RvbWVycy9tYXN0ZXJwaWVjZS9mYWxsYmFja3MvQXFjdWlhL3RyYW5zY3JpcHQudnR0IgogICAgICB9LAogICAgICAic2VsZiI6IHsKICAgICAgICAgImhyZWYiOiAiaHR0cHM6Ly9wbGF5ZXIuc3VuZGF5c2t5LmNvbS9jdXN0b21lcnMvbWFzdGVycGllY2UvZmFsbGJhY2tzL0FxY3VpYS9zdGF0dXMuanNvbiIKICAgICAgfSwKICAgICAgImNhcHRpb25zIjogewogICAgICAgICAiaHJlZiI6ICJodHRwczovL3BsYXllci5zdW5kYXlza3kuY29tL2N1c3RvbWVycy9tYXN0ZXJwaWVjZS9mYWxsYmFja3MvQXFjdWlhL3ZpZGVvLnZ0dCIKICAgICAgfQogICB9Cn0=',
    scenes: [
      {
        name: 'Opening',
        title: 'Introduction',
        timeStamp: 0,
        timeStampEnd: 23.27,
        display: true,
      },
      {
        name: 'TheCXChallenge',
        title: 'The CX Challenge',
        timeStamp: 23.27,
        timeStampEnd: 59.39,
        display: true,
      },
      {
        name: 'AcquiaValueProp',
        title: 'Data First Approach',
        timeStamp: 59.39,
        timeStampEnd: 93.44,
        display: true,
      },
      {
        name: 'ContentDataConnection',
        title: 'Content Velocity',
        timeStamp: 93.44,
        timeStampEnd: 124.12,
        display: true,
      },
      {
        name: 'SSkyValueProp',
        title: 'Acquia + SundaySky',
        timeStamp: 124.12,
        timeStampEnd: 137.45,
        display: true,
      },
      {
        name: 'TenetsOfVX',
        title: 'VX Capabilities',
        timeStamp: 137.45,
        timeStampEnd: 159.95,
        display: true,
      },
      {
        name: 'SceneDynamics',
        title: 'Scene Flexibility',
        timeStamp: 159.95,
        timeStampEnd: 180.91,
        display: true,
      },
      {
        name: 'DeliveryChannels',
        title: 'Delivery Channels',
        timeStamp: 180.91,
        timeStampEnd: 200.83,
        display: true,
      },
      {
        name: 'TheValueOfVX',
        title: 'Outcomes & ROI',
        timeStamp: 200.83,
        timeStampEnd: 215.87,
        display: true,
      },
      {
        name: 'CTA',
        title: 'CTA',
        timeStamp: 215.87,
        timeStampEnd: 232.50,
        display: false,
      },
    ],
  },
  '7': {
    nameForHfe: 'Editor',
    title: 'The Editor',
    DEV_MODE: false,
    action: 'Watch next',
    fallbackSession:
        'ewogICAicHJvamVjdGlkIjogInZweC9TdG9yeSIsCiAgICJfbGlua3MiOiB7CiAgICAgICJ2aWRlby1zdHJlYW0iOiBbCiAgICAgICAgIHsKICAgICAgICAgICAgImhyZWYiOiAiaHR0cHM6Ly9wbGF5ZXIuc3VuZGF5c2t5LmNvbS9jdXN0b21lcnMvbWFzdGVycGllY2UvZmFsbGJhY2tzL0VkaXRvci92aWRlby5tcDQiLAogICAgICAgICAgICAiaWQiOiAidmlkZW8iLAogICAgICAgICAgICAibWVkaWEtdHlwZSI6ICJ2aWRlby9tcDQiCiAgICAgICAgIH0KICAgICAgXSwKICAgICAgIm1ldGFkYXRhIjogewogICAgICAgICAiaHJlZiI6ICJodHRwczovL3BsYXllci5zdW5kYXlza3kuY29tL2N1c3RvbWVycy9tYXN0ZXJwaWVjZS9mYWxsYmFja3MvRWRpdG9yL21ldGFkYXRhLmpzb24iCiAgICAgIH0sCiAgICAgICJ0cmFuc2NyaXB0IjogewogICAgICAgICAiaHJlZiI6ICJodHRwczovL3BsYXllci5zdW5kYXlza3kuY29tL2N1c3RvbWVycy9tYXN0ZXJwaWVjZS9mYWxsYmFja3MvRWRpdG9yL3RyYW5zY3JpcHQudnR0IgogICAgICB9LAogICAgICAic2VsZiI6IHsKICAgICAgICAgImhyZWYiOiAiaHR0cHM6Ly9wbGF5ZXIuc3VuZGF5c2t5LmNvbS9jdXN0b21lcnMvbWFzdGVycGllY2UvZmFsbGJhY2tzL0VkaXRvci9zdGF0dXMuanNvbiIKICAgICAgfSwKICAgICAgImNhcHRpb25zIjogewogICAgICAgICAiaHJlZiI6ICJodHRwczovL3BsYXllci5zdW5kYXlza3kuY29tL2N1c3RvbWVycy9tYXN0ZXJwaWVjZS9mYWxsYmFja3MvRWRpdG9yL3ZpZGVvLnZ0dCIKICAgICAgfQogICB9Cn0=',
    scenes: [
      {
        name: 'Opening',
        title: 'Introduction',
        timeStamp: 0,
        timeStampEnd: 17.64,
        display: true,
      },
      {
        name: 'Plan',
        title: 'Plan Your Strategy',
        timeStamp: 17.64,
        timeStampEnd: 38.56,
        display: true,
      },
      {
        name: 'Create',
        title: 'Create Your VX',
        timeStamp: 38.56,
        timeStampEnd: 71.80,
        display: true,
      },
      {
        name: 'Scale',
        title: 'Scale Across Your CX',
        timeStamp: 71.80,
        timeStampEnd: 92.08,
        display: true,
      },
      {
        name: 'Analyze',
        title: 'Analyze Performance',
        timeStamp: 92.08 ,
        timeStampEnd: 119.76,
        display: true,
      },
      {
        name: 'CTA',
        title: 'CTA',
        timeStamp: 119.76,
        timeStampEnd: 135,
        display: false,
      }
    ],
  }
};
