import React from 'react';
import PropTypes from 'prop-types';
import css from './PlayersContainer.module.scss';

import Player from './player/Player';

function PlayersContainer({ players, activePlayer, SF }) {
  return (
    <div className={`${css.root} u-page-width`}>
      {players.map((player, i) => {
        let playerNum = i + 1;
        let active = activePlayer === playerNum;
        return (
          <Player
            number={playerNum}
            key={'sskyplayer' + playerNum}
            active={active}
            SF={SF}
            index={i}
          />
        );
      })}
    </div>
  );
}

PlayersContainer.propTypes = {
  players: PropTypes.array.isRequired,
  activePlayer: PropTypes.number.isRequired,
  SF: PropTypes.string.isRequired,
};

export default PlayersContainer;
