import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import css from './Search.module.scss';

import { updateSearchActive } from '../../../redux/actions/initialActions';

import SearchResults from './searchResults/SearchResults';

function Search({ searchTerms, updateSearchActive }) {
  const [value, setValue] = useState('');
  const [searchTerm, setSearchTerm] = useState(null);
  const [results, setResults] = useState([]);
  const [showDropDown, setShowDropDown] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsScenes, setSearchResultsScenes] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [doSearch, setDoSearch] = useState(false);

  let searchWords = searchTerms.map(term => term.text);
  searchWords = [...new Set(searchWords)];

  function initialize() {
    setValue('');
    setSearchTerm('');
    setResults([]);
    setShowDropDown(false);
    setSearchResults([]);
    setShowResults(false);
    setDoSearch(false);
    updateSearchActive(false);
  }

  function handleChange(event) {
    let input = event.target.value;
    setValue(input);
    if (input !== '') {
      setShowDropDown(true);
    } else {
      setShowDropDown(false);
    }
  }

  function search() {
    if (value) {
      let searchRes = searchTerms.filter(term =>
        term.text.toLowerCase().replace('-', ' ').match(searchTerm.toLowerCase().replace('-', ' '))
      );

      let searchResScenes = searchRes.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj['sceneName']).indexOf(obj['sceneName']) === pos;
      });

      setSearchResultsScenes(searchResScenes);
      setSearchResults(searchRes);
      setShowResults(true);
      setShowDropDown(false);
      updateSearchActive(true);
    }
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      setShowDropDown(false);
      search();
    }
  }

  useEffect(() => {
    if (!showDropDown && value) {
      setSearchTerm(value);
      setDoSearch(true);
    } else {
      setSearchTerm(value);
    }
  }, [value, showDropDown]);

  useEffect(() => {
    if (doSearch) {
      search();
    }
  }, [doSearch]);

  useEffect(() => {
    if (searchTerm) {
      let searchRes = searchWords.filter(term =>
        term.toLowerCase().replace('-', ' ').match(searchTerm.toLowerCase().replace('-', ' '))
      );
      if (searchRes.length === 0) searchRes = ['Try searching another keyword.'];
      setResults(searchRes);
    }
  }, [searchTerm]);

  return (
    <div className={css.root}>
      <div className={css.search}>
        <input
          type='text'
          value={showResults && searchResults.length > 0 ? '' : value}
          className={css.search__text}
          placeholder='Search all VX topics'
          onChange={e => handleChange(e)}
          onKeyPress={handleKeyPress}
          id='search-box'
          autoComplete='off'
        />
        <button
          className={css.search__button}
          onClick={() => search()}
          disabled={searchTerm ? false : true}
        >
          Search
        </button>
        {showDropDown && results.length > 0 ? (
          <div className={css.results}>
            {results.map((result, i) => {
              return (
                <div
                  className={css.results__result}
                  key={'result-' + i}
                  onClick={() => {
                    if (!result.includes('Try searching another keyword.')) {
                      setValue(result);
                      setShowDropDown(false);
                    }
                  }}
                >
                  {result}
                </div>
              );
            })}
          </div>
        ) : (
          ''
        )}
      </div>
      {showResults && searchResults.length > 0 ? (
        <SearchResults results={searchResultsScenes} searchTerm={searchTerm} onExit={initialize} />
      ) : (
        ''
      )}
    </div>
  );
}

Search.propTypes = {
  searchTerms: PropTypes.array.isRequired,
  updateSearchActive: PropTypes.func.isRequired,
};

function mapsStateToProps({ initial }, ownProps) {
  return {
    searchTerms: initial.searchTerms,
    ...ownProps,
  };
}

const mapDispatchToProps = {
  updateSearchActive,
};

export default connect(mapsStateToProps, mapDispatchToProps)(Search);
