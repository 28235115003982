import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function playersReducer(state = initialState.players, action) {
  switch (action.type) {
    case types.SESSIONS_RECEIVED_SUCCESS:
      return {
        ...state,
        players: action.players,
        numOfPlayers: action.players ? action.players.length : 0,
        maxOrder: action.players ? action.players.length : 0,
      };
    case types.UPDATE_ACTIVE_PLAYER_SUCCESS: {
      let newPlayerInfo = state.players.find((player, i) => i === action.oldPlayerNumber - 1);
      let playerWithNewOrder = newPlayerInfo
        ? { ...newPlayerInfo, order: action.maxOrder + 1 }
        : newPlayerInfo;
      return {
        ...state,
        activePlayer: action.newPlayerNumber,
        maxOrder: action.maxOrder + 1,
        players: state.players.map((player, i) =>
          i === action.oldPlayerNumber - 1 ? playerWithNewOrder : player
        ),
      };
    }
    case types.UPDATE_PLAYER_IS_PLAYING: {
      return {
        ...state,
        players: state.players.map(player =>
          player.title === action.playerInfo.title ? action.playerInfo : player
        ),
      };
    }
    case types.UPDATE_PLAYER_PROGRESS: {
      return {
        ...state,
        players: state.players.map(player =>
          player.title === action.playerInfo.title ? action.playerInfo : player
        ),
      };
    }
    case types.UPDATE_ACTIVE_SCENE: {
      return {
        ...state,
        players: state.players.map(player =>
          player.title === action.playerInfo.title ? action.playerInfo : player
        ),
      };
    }
    case types.UPDATE_PLAYER_ACTION: {
      return {
        ...state,
        players: state.players.map(player =>
          player.title === action.playerInfo.title ? action.playerInfo : player
        ),
      };
    }
    default:
      return state;
  }
}
