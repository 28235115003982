exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".logo---3q7az{display:flex;justify-content:center}.logo__img---TAIdd{transform:scale(.8) translateY(20rem)}.logo__text---17Y6m{color:#fff;font-size:2rem;margin-top:3rem}.gif---3aIZW{display:flex;justify-content:center}.gif__img---2oXIv{width:65%}@keyframes slide---2yzHs{0%{transform:translate(0)}2%{transform:translate(26.4px)}12.5%{transform:translate(26.4px)}15.5%{transform:translate(52.8px)}25%{transform:translate(52.8px)}27%{transform:translate(52.8px,26.4px)}37.5%{transform:translate(52.8px,26.4px)}39.5%{transform:translate(26.4px,26.4px)}50%{transform:translate(26.4px,26.4px)}52%{transform:translate(26.4px,52.8px)}62.5%{transform:translate(26.4px,52.8px)}64.5%{transform:translateY(52.8px)}75%{transform:translateY(52.8px)}77%{transform:translateY(26.4px)}87.5%{transform:translateY(26.4px)}89.5%{transform:translate(0)}to{transform:translate(0)}}.svg---3VX03{display:block;margin:auto;width:20rem;height:auto}.rect---32wOh{animation:slide---2yzHs 20s ease infinite}.rect1---3qM4G{animation-delay:0s}.rect2---2EfbU{animation-delay:-2.85714286s}.rect3---1qFFB{animation-delay:-5.71428571s}.rect4---146aF{animation-delay:-8.57142857s}.rect5----2lxd{animation-delay:-11.42857143s}.rect6---O_Ei8{animation-delay:-14.28571429s}.rect7---41sCW{animation-delay:-17.14285714s}", ""]);

// Exports
exports.locals = {
	"logo": "logo---3q7az",
	"logo__img": "logo__img---TAIdd",
	"logo__text": "logo__text---17Y6m",
	"gif": "gif---3aIZW",
	"gif__img": "gif__img---2oXIv",
	"svg": "svg---3VX03",
	"rect": "rect---32wOh",
	"slide": "slide---2yzHs",
	"rect1": "rect1---3qM4G",
	"rect2": "rect2---2EfbU",
	"rect3": "rect3---1qFFB",
	"rect4": "rect4---146aF",
	"rect5": "rect5----2lxd",
	"rect6": "rect6---O_Ei8",
	"rect7": "rect7---41sCW"
};