import React from 'react';
import PropTypes from 'prop-types';
import css from './Story.module.scss';

function Story({ playerInfo, number, active, handleClick, showArrow, watchText }) {
  return (
    <div
      className={`${css.root} ${active ? css.active : ''}`}
      onClick={e => handleClick(number, e)}
      style={{ order: playerInfo.order }}
    >
      <div className={`${css.next} ${watchText !== 'Watch next' ? css['next--started'] : ''}`}>
        {watchText}
      </div>
      <div
        className={`${css.story} ${watchText === 'Watch again' ? css['story--started'] : ''} ${
          showArrow ? css['story--arrow'] : ''}`}
      >
        {playerInfo.title}
      </div>
    </div>
  );
}

Story.propTypes = {
  playerInfo: PropTypes.object.isRequired,
  number: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  showArrow: PropTypes.bool.isRequired,
  watchText: PropTypes.string.isRequired,
};

export default Story;
