import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import css from './Player.module.scss';
import './player.scss';
import addEventListeners from './addEventListeners';
// import { isMobile as MobileDevice } from '../../../../scripts/general';

import { updatePlayerAction } from '../../../../redux/actions/playerActions';

function Player({
  playerInfo,
  SF,
  number,
  active,
  // index,
  // fullExperience,
  searchActive,
  windowWidth,
  numOfPlayers,
  maxOrder,
  updatePlayerAction,
}) {
  const [player, setPlayer] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);
  const [isMobile, setIsMobile] = useState(windowWidth <= 1100);
  const [displayInvite, setDisplayInvite] = useState(number === 1);
  const [shouldRender, setShouldRender] = useState(true);

  useEffect(() => {
    if (!player) {
      setPlayer(document.getElementById('sskyplayer' + number));
    } else {
      player.classList.add(css.player);
      if (navigator.appVersion.indexOf('Win') !== -1) player.classList.add('play-windows');
      addEventListeners(player, playerInfo.title, numOfPlayers, maxOrder);
      // if (index === 0 && fullExperience && !MobileDevice) {
      //   setTimeout(() => {
      //     player.play();
      //   }, 1500);
      // }
    }
  }, [player]);

  useEffect(() => {
    if (active) {
      setShouldRender(true);
    } else {
      setTimeout(() => {
        setShouldRender(false);
      }, 1000);
    }
  }, [active]);

  useEffect(() => {
    setCurrentTime(playerInfo.currentTime);
    if (playerInfo.isPlaying || currentTime > 0) {
      setDisplayInvite(false);

      if (playerInfo.action === 'Watch next') {
        updatePlayerAction(playerInfo, 'Continue watching');
      }
      if (
        playerInfo.activeScene &&
        playerInfo.activeScene.toLowerCase() === 'cta' &&
        playerInfo.action !== 'Watch again'
      ) {
        updatePlayerAction(playerInfo, 'Watch again');
      }
    }
  }, [playerInfo.currentTime, playerInfo.isPlaying, playerInfo.activeScene]);

  useEffect(() => {
    if (searchActive) player.pause();
  }, [searchActive]);

  useEffect(() => {
    setIsMobile(windowWidth <= 1100);
  }, [windowWidth]);

  function setPlayerTime(time) {
    player.play();
    player.currentTime = time;
  }

  return (
    <div
      className={`${css.playerWrapper} u-page-width ${
        active ? css['playerWrapper--active'] : shouldRender ? css['playerWrapper--out'] : ''
      }`}
    >
      <div className={css.playerSide}>
        <div className={`${css.playerSide__title} ${css['playerSide__title--desktop']}`}>
          {playerInfo.title}
        </div>
        <div className={css.chapters}>
          {playerInfo.scenes.map((chapter, i) => {
            let watched = currentTime > chapter.timeStampEnd;
            let nowPlaying = currentTime > chapter.timeStamp && currentTime < chapter.timeStampEnd;
            if (chapter.display) {
              return (
                <div
                  key={'sskyplayer' + number + '-chapter-' + (i + 1)}
                  className={`${css.chapterContainer} ${
                    nowPlaying ? css['chapterContainer--nowPlaying'] : null
                  }`}
                  onClick={() => setPlayerTime(chapter.timeStamp)}
                  style={{ order: isMobile && watched ? 1 : 0 }}
                >
                  <div
                    className={`${css.chapter} ${nowPlaying ? css['chapter--nowPlaying'] : null}`}
                  >
                    <div className={css['chapter--watched']}>
                      <img
                        src='assets/Chapters_Check Mark_344373.svg'
                        alt='Chapter Watched'
                        className={`${css['chapter--watched__image']} ${
                          watched ? css['chapter--watched__image--show'] : null
                        }`}
                      />
                    </div>
                    <div className={css.chapter__title}>
                      {/* <div
                        className={`${css.nowPlaying} ${
                          nowPlaying ? css['nowPlaying--show'] : null
                        }`}
                      >
                        Now playing
                      </div> */}
                      {chapter.title}
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
      <div className={css.playerContainer}>
        <div className={`${css.playerSide__title} ${css['playerSide__title--mobile']}`}>
          {playerInfo.title}
        </div>
        {displayInvite ? <div className={`${css.invitation}`}>Click to watch</div> : ''}
        <sundaysky-video
          session={playerInfo.session}
          id={'sskyplayer' + number}
          key={'sskyplayer' + number}
          // no-captions={playerInfo.fallback}
          transcript-button='hide'
          analytics-token={SF}
          playsinline='true'
          no-autoplay='true'
          poster='assets/invitation.gif'
          pre-roll='assets/preloader.gif'
        />
      </div>
    </div>
  );
}

Player.propTypes = {
  SF: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  playerInfo: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  fullExperience: PropTypes.bool.isRequired,
  searchActive: PropTypes.bool.isRequired,
  windowWidth: PropTypes.number.isRequired,
  numOfPlayers: PropTypes.number.isRequired,
  maxOrder: PropTypes.number.isRequired,
  updatePlayerAction: PropTypes.func.isRequired,
};

function mapsStateToProps({ players, initial }, ownProps) {
  return {
    playerInfo: players.players[ownProps.index],
    fullExperience: initial.fullExperience,
    searchActive: initial.searchActive,
    windowWidth: initial.windowWidth,
    numOfPlayers: players.numOfPlayers,
    maxOrder: players.maxOrder,
    ...ownProps,
  };
}

const mapDispatchToProps = {
  updatePlayerAction,
};

export default connect(mapsStateToProps, mapDispatchToProps)(Player);
