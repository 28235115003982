import * as types from '../../../../redux/actions/actionTypes';
import { store } from '../../../../index';
import { updateActivePlayer } from '../../../../redux/actions/playerActions';
import { ENV } from '../../../../scripts/general';

function getPlayerInfo(playerTitle) {
  return store.getState().players.players.find(player => player.title === playerTitle);
}

export default function addEventListeners(player, playerTitle, numOfPlayers, maxOrder) {
  getPlayerInfo(playerTitle);
  player.addEventListener('progress', () => updateProgress(player, playerTitle));
  player.addEventListener('scene', e => updateActiveScene(player, playerTitle, e));
  player.addEventListener('ctaClicked', e => handleCTA(e, numOfPlayers, maxOrder));
}

function updateProgress(player, playerTitle) {
  const playerInfo = getPlayerInfo(playerTitle);
  let currentTime = player.currentTime;
  let isPlaying = !player.paused;
  store.dispatch({
    type: types.UPDATE_PLAYER_PROGRESS,
    playerInfo: { ...playerInfo, currentTime, isPlaying },
  });
}

function updateActiveScene(player, playerTitle, event) {
  const playerInfo = getPlayerInfo(playerTitle);
  let activeScene = event.detail.scene.name;
  store.dispatch({
    type: types.UPDATE_ACTIVE_SCENE,
    playerInfo: { ...playerInfo, activeScene },
  });
}

function handleCTA(event, numOfPlayers, maxOrder) {
  if (event.detail && event.detail.name === 'Explore more') {
    event.preventDefault();
    if (numOfPlayers !== 1) {
      let playerNumber =
        event.srcElement &&
        event.srcElement.id &&
        parseInt(event.srcElement.id.replace('sskyplayer', ''));
      if (playerNumber === numOfPlayers) {
        store.dispatch(updateActivePlayer(playerNumber, 1, maxOrder));
      } else {
        store.dispatch(updateActivePlayer(playerNumber, playerNumber + 1, maxOrder));
      }
    } else {
      if (ENV === 'localhost') {
        window.location.href = 'http://localhost:8081/index.html';
      } else if (ENV !== 'prod') {
        window.location.href = 'https://vx.sundaysky.com/uat/index.html';
      } else {
        window.location.href = 'https://vx.sundaysky.com/index.html';
      }
    }
  }
}
