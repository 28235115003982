import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import css from './Question.module.scss';
import { isIE } from '../../../scripts/general';

function Question({ answers, selected, question, handleChange, text, windowWidth }) {
  const [value, setValue] = useState(selected);
  const [tileWidth, setTileWidth] = useState(17);
  const [tileHeight, setTileHeigt] = useState(16.5);
  const [lines, setLines] = useState(2);
  const [rndAnimation, setRndAnimation] = useState([]);
  const [rndColor, setRndColor] = useState([]);

  useEffect(() => {
    if (windowWidth === 1161) {
      setTileWidth(17);
      setTileHeigt(11.5);
    } else if (windowWidth === 1160 || windowWidth === 1100) {
      setTileWidth(16);
      setTileHeigt(10.5);
      setLines(2);
    } else if (windowWidth === 1050) {
      setTileWidth(15);
      setTileHeigt(9.5);
      setLines(2);
    } else if (windowWidth === 1000 || windowWidth === 830) {
      setTileWidth(15);
      setTileHeigt(9.5);
      setLines(3);
    } else if (windowWidth === 650) {
      setTileWidth(15);
      setTileHeigt(9.5);
      setLines(4);
    } else if (windowWidth === 500) {
      setTileWidth(15);
      setTileHeigt(9.5);
      setLines(6);
    }
  }, [windowWidth]);

  useEffect(() => {
    let rnd1 = answers.map(() => Math.random());
    let rnd2 = answers.map(() => Math.random());
    setRndAnimation(rnd1);
    setRndColor(rnd2);
  }, []);

  function displayChange(e) {
    let newValue = e.target.value;
    setValue(newValue);
    let timeout = question === 'industry' ? 1000 : 2500;
    setTimeout(() => {
      handleChange(question, newValue);
    }, timeout);
  }

  return (
    <div
      className={`${css.question} ${value ? css['question--hide'] : ''}`}
      style={{ minHeight: tileHeight * lines + 6 + 'rem' }}
    >
      <div
        className={`${css['question__question']} ${
          value !== null ? css['question__question--hide'] : ''
        }`}
      >
        {text}
        <div className={css.scrollDown}>Scroll down to see more options</div>
      </div>
      <div
        className={`${css.answers} ${value ? css['answers--nowShadow'] : ''}`}
        style={{
          width: tileWidth * Math.round(answers.length / lines) + 'rem',
          height: tileHeight * lines + 'rem',
        }}
      >
        {answers.map((answer, i) => {
          let checked = value === answer.value;
          return (
            <div
              className={`${css.answer} ${
                !checked && value
                  ? css['answer--un-checked']
                  : value
                  ? css['answer--checked']
                  : isIE
                  ? ''
                  : i % 2 === 0
                  ? css['answer--float-up']
                  : css['answer--float-down']
              }`}
              style={{
                animationDuration: checked
                  ? '1s'
                  : !value
                  ? `${rndAnimation[i] * 3 + 1.5}s`
                  : `${rndAnimation[i] * 1.5 + 0.5}s`,
              }}
              key={answer.value}
            >
              <input
                type='radio'
                name={question}
                value={answer.value}
                checked={checked}
                id={answer.value}
                className={css['answer__radio']}
                onChange={displayChange}
              />
              <label
                htmlFor={answer.value}
                className={`${css['answer__label']} ${
                  checked
                    ? css['answer__label--checked']
                    : value
                    ? css['answer__label--un-checked']
                    : ''
                } ${
                  rndColor[i] < 0.5
                    ? css['answer__label--color-1']
                    : rndColor[i] < 0.7
                    ? css['answer__label--color-2']
                    : rndColor[i] < 0.9
                    ? css['answer__label--color-3']
                    : css['answer__label--color-4']
                }`}
              >
                {answer.text}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

Question.propTypes = {
  answers: PropTypes.array.isRequired,
  selected: PropTypes.string,
  question: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  text: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  windowWidth: PropTypes.number.isRequired,
};

export default Question;
